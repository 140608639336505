<template>
  <div class="d-flex align-center">
    <v-btn text :x-small="xsmall" :small="small" :block="block" @click="open = true">
      <v-icon size="20" class="mr-2" v-if="!value">mdi-folder-plus-outline</v-icon>
      <v-icon size="20" class="mr-2" v-else>mdi-folder-outline</v-icon>
      <v-sheet :class="{'primary--text' : this.value && this.value.length}">
        {{ btnTitle }}
      </v-sheet>
      <v-icon class="ml-2" v-if="this.value && this.value.length" size="15" @click.stop="$emit('input', [])">
        mdi-close
      </v-icon>
    </v-btn>
    <choose-folder
      :limit="limit"
      @input="$emit('input', $event)"
      @cancel="$emit('cancel'); open = false"
      @set="$emit('set'); open = false;"
      v-if="open"
      :visible="open"
      :value="value"
    ></choose-folder>
  </div>
</template>

<script>
import ChooseFolder from "./ChooseFolder.vue"
export default {
  props: ['value', 'xsmall', 'small', 'block', 'limit'],
  components: { ChooseFolder },
  data: () => ({
    open: false
  }),
  methods: {
    folderChosen(f) {
      this.$emit('input', f)
      if(f) this.open = false
    }
  },
  computed: {
    btnTitle() {
      if(!this.value || this.value.length === 0) return this.$t('folder.add_to_folder')
      else if(this.value.length === 1) return this.value[0].name.substring(0, 15)
      else return `${this.value.length} ${this.$t('amount_of_folder_chosen')}` 
    }
  }
}
</script>