<template>
    <v-sheet class="d-flex align-center py-1">
      <v-sheet style="width: 40px;">
        <v-menu open-on-hover left open-delay="500" offset-x>
          <template v-slot:activator="{on, attrs}">
            <v-img v-bind="attrs" v-on="on" position="top center" :src="player.picture_url" height="35" /> 
          </template>
          <v-card>
            <v-img contain :src="player.picture_url" height="300" width="300" /> 
          </v-card>
        </v-menu>
      </v-sheet>
      <v-text-field
        v-prevent-keys
        @input="changePlayer(i, $event, 'num')"
        style="max-width: 45px;"
        class="align-center justify-center"
        dense
        solo
        hide-details
        placeholder="No"
        :value="player.num"
      ></v-text-field>
      <v-text-field
        v-prevent-keys
        @input="changePlayer(i, $event, 'player_name')"
        hide-details
        solo
        dense
        placeholder="Name"
        :value="player.player_name"
        class="px-1"
      >
        <template v-slot:append>
          <v-icon
            size="15"
            v-if="player.found"
            color="green"
            v-tooltip="'Player found by name from database'"
          >
            mdi-check
          </v-icon>
          <v-icon
            size="15"
            v-else
            color="red"
            v-tooltip="'Player NOT found by name from database. Will be added.'"
          >
            mdi-close
          </v-icon>
          <v-icon @click.stop="removePlayer({ side, index: i })" size="15" style="cursor: pointer;">
            mdi-close
          </v-icon>
        </template>
      </v-text-field>
      <v-text-field
        @input="changePlayer(i, $event, 'pos')"
        v-prevent-keys
        placeholder="Pos"
        solo
        style="width: 20px"
        dense
        hide-details
        :value="player.pos"
      >

      </v-text-field>
      <v-sheet style="cursor: pointer;" class="player-handle">
        <v-icon>mdi-drag</v-icon>
      </v-sheet>
      <!-- {{ player }} -->
    </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['player', 'i', 'side'],
  methods: {
    ...mapMutations('game', [
      'SET_LINEUPS',
      'SET_GAME_PLAYERS'
    ]),
    ...mapActions('game', [
      'removePlayer',
      'checkPlayerExists'
    ]),
    ...mapActions('noti', [
      'error',
      'info',
      'success'
    ]),
    changePlayer(index, newVal, field) {
      let mod = { ...this.game_players }
      mod[this.side][index][field] = newVal

      this.SET_LINEUPS(mod)

      this.$nextTick(() => {
        if(field == 'player_name') {
          this.checkPlayerExists({ name: newVal, side: this.side })
        }
      })
    },
  },
  computed: {
    ...mapGetters('game', [
      'game_players'
    ])
  }
}
</script>