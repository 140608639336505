<template>
  <v-container class="io-video-main hideScroll" fluid :style="{ 'flex-direction': $vuetify.breakpoint.mobile ? 'column' : 'row'}">
    <div :style="`position: relative; display: flex; flex-grow: ${$vuetify.breakpoint.mobile ? '0':'1'}`" class="io-video-left">
      <!-- Video page app bar - only visible on desktop -->
      <v-sheet v-if="!$vuetify.breakpoint.mobile" style="display: flex; justify-content: space-between; border-bottom: 1px solid lightgrey;">
        <div>
          <v-btn
            v-tooltip="'Back'"
            v-if="!$vuetify.breakpoint.mobile"
            text
            @click="$router.replace('/videos')"
            >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span v-if="video" class="text-h6 pt-1">
            {{ video.title }}
          </span>
        </div>
        <div style="display: flex; align-items: center;">
          <v-btn id="video-draw-button" v-if="!this.$vuetify.breakpoint.mobile" :loading="drawLoading" @click="playingClip ? $router.push(`/editor?clip_id=${clip.id}&from=${$route.path}`) : startDrawing()" class="ml-5" small>
            <v-icon>
              mdi-draw
            </v-icon>
            {{ $t('draw') }}
          </v-btn>
          <share-btn resource_type="video" :resource_id="$route.params.id" class="ml-2" size="small"></share-btn>
          <v-btn v-if="!$vuetify.breakpoint.mobile" text @click="toggleSideBar()">
            <v-icon v-if="show_side_bar">
              mdi-arrow-collapse-right
            </v-icon>
            <v-icon v-else>
              mdi-arrow-collapse-left
            </v-icon>
          </v-btn>
        </div>
      </v-sheet>

      <!-- Player container -->
      <v-sheet
        id="video-cont"
        @click="keepVisibleFor(3)"
        @mouseover="keepVisibleFor(3)"
        @mouseleave="resetVisible()"
        :style="`height: min(${ $vuetify.breakpoint.mobile ? 'calc( 100vw / 16 * 9)' : '' }, 100vh)`" 
        class="io-video-left-screen"
      >
          <!-- Landscape mobile button -->
          <div id="inline-bar" :style="`position: absolute; bottom: 80px; right: 0px; height: 30px; z-index: 1; opacity: ${ mediacontrol_visible ? '0.7' : '0' };`" v-if="$vuetify.breakpoint.mobile">
            <Bar
              :showMostAnyway="false"
              :hidePlay="true"
            ></Bar>
          </div>
          <!-- Player component -->
          <editor-player
            v-if="options"
            :options="options"
            :key="playerKey"
          ></editor-player>
      </v-sheet>
      <v-sheet v-if="!$vuetify.breakpoint.mobile" id="bar" style="position: relative;" :class="{ 'mobile-bar': $vuetify.breakpoint.mobile }">
        <v-btn v-if="!show_timeline && !$vuetify.breakpoint.mobile" x-small @click="showTimeline()" style="position: absolute; top: -30px; right: calc(50% - 50px);">
          <v-icon size="15" class="mr-2">mdi-chart-gantt</v-icon>
          {{ $t('show_timeline') }}
        </v-btn>
        <Timeline v-show="show_timeline"></Timeline>
        <Bar v-show="!show_timeline">
        </Bar>
      </v-sheet>
    </div>
    <v-sheet class="io-video-right hideScroll" :style="rightWidth">
      <AnalysisBar :clipsOnly="!!manual_video" :relevantClip="relevantClip" :isAnalysis="isAnalysis"></AnalysisBar>
    </v-sheet>

    <!-- Mobile back button -->
    <v-btn id="backbutton" small @click="$router.replace('/videos')" style="position: fixed; left: 2vh; top: 2vh; z-index: 2; opacity: 0.5" v-if="$vuetify.breakpoint.mobile">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import AnalysisBar from '@/components/video/analysisBar/AnalysisBar.vue'
// import Player from '@/components/video/Player.vue' 
import EditorPlayer from '@/components/video/ClipEditor/EditorPlayer.vue' 
import Bar from '@/components/video/player/Bar'
import ShareBtn from '@/components/share/ShareBtn'
import Timeline from '@/components/video/player/Timeline'
import ClapprMarkersPlugin from 'clappr-markers-plugin'
import time from '@/utils/player/time'
// import { VueScreenSizeMixin } from 'vue-screen-size';
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  props: ['manual_video'],
  components: {
    AnalysisBar,
    EditorPlayer,
    Bar,
    ShareBtn,
    Timeline
  },
  mixins: [
    time, 
    // VueScreenSizeMixin
  ],
  created() {
    let start_at = this.$route.query.t

    if(this.manual_video) {
      this.setVideo(this.manual_video)
      this.$nextTick(() => {
        this.options = {
          source: this.video.mp4_url || this.video.original_url,
          poster: this.video.thumb_url,
          mimeType: 'video/mp4',
          start_at
        }
        this.playerKey++
      })

      return;
    }

    if(!this.isAnalysis && !this.isGame) {
      this.videoById(this.$route.params.id)
        .then(() => {
          this.options = {
            source: this.video.mp4_url || this.video.original_url,
            poster: this.video.thumb_url,
            mimeType: 'video/mp4',
            start_at
          }
          this.playerKey++
        })
        .catch(err => {
          this.error(err)
        })
    } else {
      // Liigat
      this.gameById(this.$route.params.id)
        .then((e) => {
          this.initLeagues(e.data.league_id)
          this.initGamePlayers({ league_game_id: e.data.id, home_club_id: e.data.home_club_id })

          this.options = { 
            mimeType: this.video.video_type == 'hls' ? 'application/x-mpegURL' : 'video/mp4',
            source: this.video.video_url || this.video.original_url,
            start_at
          }
          this.playerKey++

          this.$nextTick(() => {
            let points = this.getPointsFromClips(this.video.clips)

            if(points[0]) {
              let marker1 = new ClapprMarkersPlugin.StandardMarker(points[0], '1st half')
              this.player?.core?.plugins[1].addMarker(marker1)
            }
            if(points[1]) {
              let marker2 = new ClapprMarkersPlugin.StandardMarker(points[1], '2nd half')
              this.player?.core?.plugins[1].addMarker(marker2)
            }
          })
        })
        .catch(err => {
          this.showSnackbar({
            text: err.response.data,
            type: 'error'
          })
        })
    }

    window.addEventListener('resize', () => {
      this.refresh_player_dimensions++;
    })
  },
  mounted() {
    // Add event listener for 'keydown' events
    window.addEventListener('keydown', this.preventSpacebarScroll);
  },
  data: () => ({
    playerKey: 0,
    options: null,
    relevantClip: null,
    isDarkModeEnabled: window.localStorage.getItem('tiimio-dark') != 'true',
    visibleTimeout: null,
    currentDataOnScreen: [],
    refresh_player_dimensions: 0,
    drawLoading: false
  }),
  computed: {
    ...mapGetters('videos', [
      'video',
      'show_side_bar',
      'show_timeline'
    ]),
    ...mapGetters('player', [
      'player',
      'currentTime',
      'markerPlugin',
      'clip',
      'accurateCurrentTime',
      'mediacontrol_visible',
      'playingClip'
    ]),
    ...mapGetters('draw', [
      'canvas_dimensions_key'
    ]),
    playerDimensions() {
      this.refresh_player_dimensions;
      this.show_side_bar;
      this.canvas_dimensions_key;

      const wrap = document.querySelector('#video-cont')
      let he = wrap.offsetHeight
      let wi = wrap.offsetWidth

      const tops_touch = he / 9 < wi / 16

      if(tops_touch) {
        // landscaped
        return {
          width: (he * 16 / 9),
          height: he
        }
      } else {
        // portrait
        return {
          width: wi,
          height: (wi * 9 / 16)
        }
      }
    },
    isAnalysis() {  
      return this.$route?.path?.includes('analysis')
    },
    isGame() {
      return this.$route?.path?.includes('games')
    },
    rightWidth() {
      if(this.$vuetify.breakpoint.mobile) return { width: '100%' }
      if(!this.show_side_bar) return "width: 0px; max-width: 0px;"
      return "width: 450px !important; max-width: 450px;"
    }
  },
  methods: {
    ...mapActions('videos', [
      'videoById',
      'gameById',
      'toggleSideBar'
    ]),
    ...mapActions('admin', [
      'initLeagues'
    ]),
    ...mapActions('game', [
      'initGamePlayers'
    ]),
    ...mapActions('noti', [
      'showSnackbar',
      'error',
      'success'
    ]),
    ...mapMutations('draw', [
      'UPDATE_SCALE'
    ]),
    ...mapActions('clip', [
      'addClip'
    ]),
    ...mapMutations('videos', [
      'setShowSideBar',
      'setVideo',
      'setShowTimeline'
    ]),
    showTimeline() {
      this.setShowTimeline('true')

      this.$nextTick(() => {
        this.UPDATE_SCALE()
      })
    },
    preventSpacebarScroll(event) {
      // Check if the pressed key is spacebar (key code 32)
      if (event.keyCode === 32 && (event.target === document.body || event.target.id == 'tag-adder')) {
        // Prevent the default action to stop scrolling
        event.preventDefault();
        // Your code to play/pause the video goes here
      }
    },
    startDrawing() {
      this.drawLoading = true;

      this.addClip({
        leaguewide: false,
        title: "New clip",
        starttime: Math.max(this.currentTime - 3),
        endtime: this.currentTime + 3,
        tags: [],
        video_id: this.isGame ? undefined : this.$route.params.id,
        game_id: !this.isGame ? undefined : this.$route.params.id,
      })
      .then(e => {
        this.$router.push(`/editor?clip_id=${e.data?.id}&from=${this.$route.path}&seek=${this.accurateCurrentTime}&delete_if_no_graphics=true`)
      })
      .catch((e) => {
        this.error(e)
      })
      .finally(() => {
        this.drawLoading = false;
      })
    },
    keepVisibleFor(time) {
      clearTimeout(this.visibleTimeout)

      this.player?.core.mediaControl.setKeepVisible()
      this.visibleTimeout = setTimeout(() => {
        this.player?.core.mediaControl.resetKeepVisible()
      }, time * 1000)
    },
    resetVisible() {
      this.player?.core.mediaControl.resetKeepVisible()
    }
  },
  watch: {
    currentTime: {
      handler(newVal) {
        this.relevantClip = this.findRelevantClip(newVal, this.video.clips)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.io-video {
  overflow-x: hidden;

  &-main {
    overflow-x: hidden;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  &-left {
    display: flex;
    flex-direction: column;

    &-screen {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
    }

    &-bar {
      height: 10vh;
    }
  }

  &-right {
    max-height: 100vh;
    overflow-y: scroll;
    border-left: 2px solid rgb(208, 208, 208);
  }
}

#inline-bar {
  transition-duration: 0.3s;
  width: 100vw;
  display: flex;
  justify-content: center;
}

@media (orientation: landscape) {
  .mobile-bar {
    display: none;
  }

  #inline-bar {
    display: block;
  }

  #backbutton {
    top: 10px !important; 
  }
}

@media (orientation: landscape) and (max-width: 960px) {
  #video-cont {
    height: 100vh !important;
  }
}

@media (orientation: portrait) {
  #inline-bar {
    display: none;
  }
}

</style>