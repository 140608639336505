<template>
  <v-sheet class="io-timeline hide-scrollbar">
    <div @pointerdown="handleDragStart" class="io-timeline-cont hide-scrollbar">
      <v-card class="io-timeline-cont-tooltipmargin hide-scrollbar">
        <v-btn-toggle v-model="show_tags_type_model" style="align-self: center;">
          <v-btn value="action" text x-small>{{ $t('actions') }}</v-btn>
          <v-btn value="metadata" text x-small>{{ $t('metadata') }}</v-btn>
        </v-btn-toggle>
        <v-btn style="align-self: center;" @click="hideSelf()" x-small>
          <v-icon size="15">mdi-play-outline</v-icon>
          {{ $t('show_controls') }}
        </v-btn>
        <v-btn-toggle style="align-self: center;" class="mr-3">
          <v-btn x-small @click="zoomIn({ value: 1 })"><v-icon>mdi-plus</v-icon></v-btn>
          <v-btn x-small>{{(zoom * 100).toFixed(0)}} %</v-btn>
          <v-btn x-small @click="zoomOut({ value: 1 })"><v-icon>mdi-minus</v-icon></v-btn>
        </v-btn-toggle>
        <div v-dragged="handleDragged" v-if="scrollbar_width != main_width" :style="`background-color: #2196F3; width: ${scrollbar_width}px; left: ${scrollbar_left + 100}px; bottom: 0px; border-radius: 5px; position: absolute; height: 8px; cursor: pointer;`"></div>
        <div v-dragged="handleCursorDragged" :style="`cursor: pointer; border-radius: 2px; top: 20px; width: 9px; height: 10px; background-color: blue; position: absolute; left: ${calculate_cursor_left + 97}px;`"></div>
        <div v-dragged="handleCursorDragged" :style="`cursor: pointer; top: 28px; width: 3px; z-index: 10; height: 200px; background-color: blue; position: absolute; left: ${calculate_cursor_left + 100}px;`"></div>
      </v-card>
      <div v-if="timeline_row_tags.length">
        <TimelineRow
          class="io-timeline-cont-row hide-scrollbar"
          :color="tag.map_color"
          :tag="tag"
          v-for="tag in timeline_row_tags"
          :key="tag.id"
        ></TimelineRow>
      </div>
      <div style="display: flex; justify-content: center;" v-else>
        <v-card flat>
          <v-card-title>
            {{$t('no_data')}}
          </v-card-title>
          <v-card-text>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import TimelineRow from '@/components/video/player/TimelineRow'
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  data: () => ({
    colors: [
      "#FF5733", // Vivid Red-Orange
      "#6A0572", // Deep Purple
      "#FFC300", // Vivid Yellow
      "#00BCD4", // Vibrant Cyan
      "#FF0090", // Strong Pink
      "#4CAF50", // Fresh Green
      "#FF3E4D", // Intense Coral
      "#795548", // Warm Brown
      "#9C27B0", // Deep Purple
      "#FF9800", // Bright Orange
      "#FF6F61", // Pastel Red
      "#5D8AA8", // Soft Blue
      "#FFCD02", // Yellow-Green
      "#581845", // Rich Purple
      "#2E294E", // Dark Violet
      "#96C93D", // Lime Green
      "#FF8833", // Vivid Orange
      "#3A1C71", // Dark Blue
      "#F8B400", // Vivid Yellow
      "#FF2E63", // Strong Pink
      "#6C757D", // Cool Grey
      "#00A86B", // Jade Green
      "#FF5858", // Light Coral Pink
      "#FF7A5A", // Salmon Pink
      "#B4674D", // Rusty Red
      "#B05A28", // Warm Brown
      "#2B2D42", // Dark Slate Blue
      "#FFD700", // Gold
      "#48466D", // Purple Navy
      "#2E86AB", // Cerulean Blue
      "#FF5733", // Vivid Red-Orange
      "#6A0572", // Deep Purple
      "#FFC300", // Vivid Yellow
      "#00BCD4", // Vibrant Cyan
      "#FF0090", // Strong Pink
      "#4CAF50", // Fresh Green
      "#FF3E4D", // Intense Coral
      "#795548", // Warm Brown
      "#9C27B0", // Deep Purple
      "#FF9800", // Bright Orange
      "#FF6F61", // Pastel Red
      "#5D8AA8", // Soft Blue
      "#FFCD02", // Yellow-Green
      "#581845", // Rich Purple
      "#2E294E", // Dark Violet
      "#96C93D", // Lime Green
      "#FF8833", // Vivid Orange
      "#3A1C71", // Dark Blue
      "#F8B400", // Vivid Yellow
      "#FF2E63", // Strong Pink
      "#6C757D", // Cool Grey
      "#00A86B", // Jade Green
      "#FF5858", // Light Coral Pink
      "#FF7A5A", // Salmon Pink
      "#B4674D", // Rusty Red
      "#B05A28", // Warm Brown
      "#2B2D42", // Dark Slate Blue
      "#FFD700", // Gold
      "#48466D", // Purple Navy
      "#2E86AB"  // Cerulean Blue
    ],
    handleDragInterval: null
  }),
  components: { TimelineRow },
  mounted() {
    const element = document.querySelector('.io-timeline-cont')
    element.addEventListener("wheel", this.handleWheel);
    element.addEventListener("scroll", this.handleScroll);
    element.addEventListener("pointerleave", this.endDragging);

    window.addEventListener('mouseup', this.endDragging)
    window.addEventListener('touchend', this.endDragging)
    // element.addEventListener("pointermove", this.handleDragStart);

    if(!this.tag_groups || !this.tag_groups.length) this.initTags()
  },
  /*
    Mitkä actionit, metadatat ja backgroundit näytetään
    Defaulttina näytetään kaikki backgroundit ja actionit
  */
  methods: {
    ...mapActions('ticks', [
    ]),
    ...mapActions('tag', [
      'initTags'
    ]),
    ...mapActions('timeline', [
      'zoomIn',
      'zoomOut',
      'moveHorisontally',
      'moveScrollbar',
      'moveCursor'
    ]),
    ...mapMutations('draw', [
      'UPDATE_SCALE'
    ]),
    ...mapMutations('timeline', [
      'SET_SHOW_TAGS_TYPE'
    ]),
    ...mapMutations('videos', [
      'setShowTimeline'
    ]),
    handleDragStart() {
      // var self = this
      // this.handleDragInterval = setInterval(() => {
      //   self.findMousePosAndGoThere()
      // }, 100)
    },
    hideSelf() {
      this.setShowTimeline(false)

      this.$nextTick(() => {
        this.UPDATE_SCALE()
      })
    },
    handleDragged(e) {
      this.moveScrollbar(e.deltaX)
    },
    handleCursorDragged(e) {
      this.moveCursor(e.deltaX)
    },
    findMousePosAndGoThere() {
      console.log('ho')
    },
    endDragging() {
      clearInterval(this.handleDragInterval)
      this.handleDragInterval = null
    },
    handleRowClick(e) {
      console.log(e)
    },
    handleWheel(e) {
      if(e.ctrlKey) {
        e.stopPropagation();
        e.preventDefault();
      }
      // console.log(e.deltaX)
      // console.log(e.deltaY)

      const main = document.querySelector('.io-timeline-cont-row-main')
      const { x } = main.getBoundingClientRect()

      if(e.wheelDeltaX) this.moveHorisontally(e.wheelDeltaX)
      if(e.wheelDeltaY > 0 && e.ctrlKey) this.zoomIn({ cursor_left: e.x, main_left: x })
      if(e.wheelDeltaY < 0 && e.ctrlKey) this.zoomOut({ cursor_left: e.x, main_left: x })
    },
    handleScroll(e) {
      console.log(e)
    }
  },
  computed: {
    ...mapGetters({
      duration: 'player/duration',
      timeline_row_tags: 'ticks/timeline_row_tags',
      tag_groups: 'tag/tag_groups',
      canvas_dimensions_key: 'draw/canvas_dimensions_key'
    }),
    ...mapGetters('timeline', [
      'zoom',
      'calculate_cursor_left',
      'scrollbar_width',
      'scrollbar_left',
      'main_width',
      'show_tags_type'
    ]),
    show_tags_type_model: {
      set(newVal) {
        this.SET_SHOW_TAGS_TYPE(newVal)
      },
      get() {
        return this.show_tags_type
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.io-timeline {
  height: 220px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: scroll;
  position: relative;
  overscroll-behavior-x: contain;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;

  &-cont {
    flex-grow: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: stretch;

    &-tooltipmargin {
      height: 30px;
      position: sticky;
      top: 0px;
      display: flex;
      width: 100%;
      z-index: 5;
      justify-content: space-between;
    }

    &-row {
      width: 100%;
      height: 30px;
      max-height: 30px;
      display: flex;
      // flex-grow: 1;
      border: 1px solid grey;
      border-radius: 5px;
    }
  }
}
</style>