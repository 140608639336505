<template>
  <v-card min-height="300" class="pa-5">
    <div class="text-h5 text-uppercase mb-3">
      {{ $t('hotkey') }}
    </div>
    <div v-if="loading_hotkeys || loading_tag_views">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <div class="text-button">
        {{ $t('general') }}
      </div>
      <EditAndCreateHotkey
        :removable="false"
        v-for="hotkey in hotkeys.filter(t => !!t.hotkey_action)" :key="hotkey.hotkey_action"
        :hotkey="hotkey"
      ></EditAndCreateHotkey>
      <div class="text-button">
        {{ $t('tags.tags') }}
      </div>
      <EditAndCreateHotkey
        :removable="true"
        v-for="hotkey in hotkeys.filter(t => !!t.tag_id)" :key="hotkey.id"
        :hotkey="hotkey"
      ></EditAndCreateHotkey>
      <div>
        <AddHotkey></AddHotkey>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditAndCreateHotkey from '@/components/tag_views/hotkey/EditAndCreateHotkey'
import AddHotkey from '@/components/tag_views/hotkey/AddHotkey'

export default {
  components: {
    EditAndCreateHotkey,
    AddHotkey
  },
  data: () => ({
  }),
  created() {
    this.getTeamHotkeys()
    if(!this.tag_views.length) this.initTagViews()
  },
  methods: {
    ...mapActions('hotkey', [
      'getTeamHotkeys'
    ]),
    ...mapActions('tag_vier', [
      'initTagViews'
    ])
  },
  computed: {
    general_hotkeys() {
      return [
        { text: this.$t('hide_map'), hotkey_action: 'toggle_map' },
        { text: this.$t('toggle_timer'), hotkey_action: 'toggle_timer' },
        { text: this.$t('next_clip'), hotkey_action: 'next_clip' },
        { text: this.$t('previous_clip'), hotkey_action: 'previous_clip' },
        { text: this.$t('delete_latest_clip'), hotkey_action: 'delete_latest_clip' }
      ]
    },
    ...mapGetters('hotkey', [
      'hotkeys',
      'loading_hotkeys'
    ]),
    ...mapGetters('tag_view', [
      'tag_views',
      'loading_tag_views'
    ])
  }
}
</script>