var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow-x":"hidden"}},[_c('div',{staticClass:"io-timeline-cont-row-left hide-scrollbar"},[_c('div',{style:(`
      height: 90%;
      min-width: 1px;
      width: 5px;
      background-color: ${_vm.color || _vm.$vuetify.theme.currentTheme.primary};
      border-radius: 5px;
    `)}),_c('div',{staticClass:"ml-1 hide-scrollbar"},[_vm._v(" "+_vm._s(_vm.tag.name)+" ")]),_c('v-spacer')],1),_c('div',{staticClass:"io-timeline-cont-row-main hide-scrollbar"},[_c('div',{staticClass:"hide-scrollbar",staticStyle:{"height":"28px","position":"absolute","top":"0px","overflow-x":"hidden"},style:(`width: ${_vm.whole_bar_width}px; left: -${_vm.pixels_hidden_left}px`)},_vm._l((_vm.tag_clips(_vm.tag.id)),function(clip){return _c('div',{key:clip.id,staticClass:"io-timeline-clip pl-2 hide-scrollbar",style:(`
          cursor: pointer;
          position: absolute;
          left: ${_vm.clip_bar_left(clip)}px;
          width: ${_vm.clip_bar_width(clip)}px;
          background-color: ${_vm.color || _vm.$vuetify.theme.currentTheme.primary};
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, .5);
        `),on:{"click":function($event){return _vm.seek(clip.starttime)}}})}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }