<template>
  <v-card>
    <div class="pa-5">
      <v-sheet class="text-h5">
        {{ $t('add_tag') }}
      </v-sheet>
      <v-sheet class="mt-5">
        <div class="d-flex align-end">
          <v-text-field
            :label="$t('tag_name')"
            autofocus
            v-model="tag_name"
          >

          </v-text-field>
        </div>
        <div class="d-flex align-start">
          <form-hotkey
            v-model="hotkey"
          ></form-hotkey>
        </div>
        <div>
          <div class="text-button mt-5">
            {{ $t('tag_color') }}
          </div>
          <v-sheet class="d-flex" style="height: 30px;">
            <map-point
              :color="map_color"
              :size="40"
              shape="rounded"
              @point-clicked="show_colors = !show_colors"
            ></map-point>

            <!-- CHOOSABLE COLORS -->
            <v-expand-x-transition>
              <v-sheet
                v-if="show_colors"
                class="d-flex align-center ml-2 pr-1" elevation="3"
                style="height: 30px; border-radius: 15px; display: inline-block;"
              >
                <map-point
                  v-for="color in colors"
                  :key="color"
                  @point-clicked="show_colors = false; map_color = color;"
                  class="ml-2"
                  size="20"
                  :color="color"
                ></map-point>
              </v-sheet>
            </v-expand-x-transition>
          </v-sheet>
        </div>
        <div class="d-flex justify-center">
          <v-btn @click="show_advanced_options = !show_advanced_options" small rounded>
            <span v-if="!show_advanced_options">
              {{ $t('show_advanced_options') }}
            </span>
            <span v-else>
              {{ $t('hide_advanced_options') }}
            </span>
            <v-icon v-if="!show_advanced_options">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-up</v-icon>
          </v-btn>
        </div>
        <v-expand-transition>
          <div v-if="show_advanced_options">
            <div class="d-flex">
              <div class="flex-grow-1">
                <v-select
                  v-prevent-keys
                  :value="null"
                  :label="$t('map_shape')"
                  v-model="map_shape"
                  :items="shapes"
                  item-text="name"
                  item-value="id"
                >
                  <template v-slot:item="{item}">
                    <div class="d-flex align-center">
                      <div class="mr-3" :style="shape_css(item.id)" style="height: 20px; width: 20px; border: 1px solid black;"></div>
                      <div>
                        {{ item.name }}
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>

              <!-- BIG POINT PREVIEW -->
              <div class="d-flex align-center justify-center px-10">
                <map-point
                  :shape="map_shape"
                  :color="map_color"
                  size="40"
                ></map-point>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </v-sheet>
    </div>
    <v-card-actions>
      <v-btn @click="$emit('close'); clear();">
        {{ $t('cancel') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="!this.tag_name" @click="handleSaveClick(false, 'button')" color="primary">
        {{ $t('save') }}
        <v-icon label class="ml-2" size="20">mdi-keyboard-return</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import MapPoint from '@/components/maps/MapPoint.vue'
import { mapActions } from 'vuex'
import FormHotkey from '@/components/FormHotkey.vue'
export default {
  shortcuts: {
    keydown: function(e) {
      if(e.keyCode === 13) {
        this.handleSaveClick(e.shiftKey, 'keydown')
      }
      return false
    }
  },
  components: {
    MapPoint,
    FormHotkey,
  },
  props: ['editing_tag', 'group_id'],
  created() {
    if(!this.editing_tag) return
    this.tag_name = this.editing_tag.tag_name
    this.map_color = this.editing_tag.map_color
    this.map_shape = this.editing_tag.map_shape
    this.hotkey['hotkey'] = this.editing_tag.hotkey
    this.hotkey['shift'] = this.editing_tag.shift
  },
  data: () => ({
    colors: [
      // null,
      // "#FFFFFF", // White
      "#000000", // Black
      "#FF0000", // Bright Red
      "#00FF00", // Bright Green
      "#2D57FB", // Bright Blue
      "#FFFF00", // Yellow
      "#FF00FF", // Magenta
      "#00FFFF", // Cyan
      "#800000", // Dark Maroon
      "#008080",  // Teal
    ],
    show_colors: false,
    tag_name: '',
    map_color: "#2D57FB",
    map_shape: null,
    show_advanced_options: false,
    hotkey: {
      shift: null,
      hotkey: null
    }
  }),
  methods: {
    ...mapActions('tag_view', [
      'updateTag',
      'createTag'
    ]),
    ...mapActions('noti', [
      'success',
      'info',
      'error'
    ]),
    shape_css(shape) {
      if(shape == 'none') shape = 'circle'
      switch(shape) {
        case 'circle':
          return { borderRadius: '50%', transform: 'scale(1.20)' }
        case 'diamond':
          return { transform: 'rotate(45deg) scale(0.92)' }
      }
    },
    handleSaveClick(shift_pressed) {
      if(!this.tag_name) return
      if(this.editing_tag) this.update(shift_pressed)
      else this.create(shift_pressed)
    },
    create(shift_pressed) {
      const newTag = {
        map_shape: this.map_shape,
        map_color: this.map_color,
        tag_name: this.tag_name,
        group_id: this.group_id,
        shift: this.hotkey.shift,
        hotkey: this.hotkey.hotkey,
      }

      this.createTag(newTag)
        .then(() => {
          this.info(this.$t('tag_created'))
          if(shift_pressed) this.$emit('close-and-new')
          else this.$emit('close')
        })
        .catch(e => {
          this.error(e)
        })
    },
    update(shift_pressed) {
      const updatedTag = {
        map_shape: this.map_shape,
        map_color: this.map_color,
        tag_name: this.tag_name,
        id: this.editing_tag.id,
        shift: this.hotkey.shift,
        hotkey: this.hotkey.hotkey
      }
      this.updateTag(updatedTag)
        .then(() => {
          this.info(this.$t('tag_updated'))
          if(shift_pressed) this.$emit('close-and-new')
          this.$emit('close')
        })
        .catch(e => {
          this.error(e)
        })
    },
    clear() {
      this.tag_name = ''
      this.map_shape = null
      this.map_color = null
      this.hotkey = ''
    }
  },
  computed: {
    shapes() {
      return [
        { id: null, name: this.$t('none') },
        { id: 'circle', name: this.$t('circle') },
        { id: 'rectangle', name: this.$t('square') },
        { id: 'diamond', name: this.$t('diamond') },
        // { id: 'cross', name: this.$t('cross') },
      ]
    }
  }
}
</script>