<template>
  <!-- MAIN COMPONENT -->
  <v-sheet
    style="border-radius: 10px; position: relative;"
    :style="`border: ${ mode !== 'clip' ? 1 : 0 }px solid lightgrey;`"
    class="my-1 d-flex"
    :elevation="mode === 'clip' ? 0 : 1"
  >
    <div>
      <div class="d-flex align-center">
        <div v-if="mode !== 'clip'" class="text-button ml-3 mr-3 pb-0">
          {{ group.group_name }}
        </div>
        <div
          v-else
          style="font-size: .8rem; text-align: center;"
          class="ml-1 grey--text text-uppercase"
        >
          {{ group.group_name }}
        </div>
        <v-chip v-if="amountOfChosen && mode !== 'clip'" small label color="secondary">
          <v-icon size="15" class="mr-2">mdi-tag-outline</v-icon>
          {{ amountOfChosen }}
          <v-icon
            @click="emptyTagsFromGroup"
            size="15"
            class="ml-2"
          >mdi-close</v-icon>
        </v-chip>
      </div>
      <v-sheet
        style="border-radius: 5px;"
        class=" d-flex justify-center;"
        :class="{
          'pa-1 pt-0': mode === 'clip',
          'pa-3 pt-0 mb-2': mode !== 'clip'
        }"
      >
        <group-tags
          :value="value"
          @click:tag="$emit('click:tag', $event)"
          @click-right:tag="$emit('click-right:tag', $event)"
          :group="group"
        ></group-tags>
      </v-sheet>
    </div>
    <v-spacer></v-spacer>
    <v-sheet v-if="mode === 'edit'" class="d-flex flex-column justify-space-between" style="border-radius: 10px;">
      <!-- THREE DOTS BTN -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            fab
            text
            x-small
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group>
            <v-list-item @click="editing = true">
              <v-list-item-icon>
                <v-icon size="20">mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('edit') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleting = true">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('delete') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-sheet style="cursor: pointer;" class="align-self-center justify-self-center handle">
        <v-icon>
          mdi-drag-vertical
        </v-icon>
      </v-sheet>
      <v-sheet style="height: 35px; width: 0px;"></v-sheet>
    </v-sheet>

    <!-- HELPER OBJECTS -->
    <v-dialog
      v-model="editing"
      max-width="800"
    >
      <create-group
        @close="editing = false"
        v-if="editing"
        :editing_group="group"
      ></create-group>
    </v-dialog>
    <confirmation-dialog
      v-if="deleting"
      :show="deleting"
      :text="`${$t('delete')} ${group.group_name}?`"
      :btnText="$t('delete')"
      :subText="$t('delete_group_long')"
      btnColor="error"
      @decline="deleting = false"
      @accept="deleteGroup()"
    ></confirmation-dialog>
  </v-sheet>
</template>

<script>
import GroupTags from '@/components/tag_views/tags/GroupTags.vue'
import CreateGroup from '@/components/tag/CreateGroup.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex/dist/vuex.common.js';

export default {
  props: ['group', 'dragging', 'value'],
  components: {
    GroupTags,
    CreateGroup,
    ConfirmationDialog
  },
  data: () => ({
    editing: false,
    deleting: false
  }),
  computed: {
    ...mapGetters('tag_view', [
      'mode'
    ]),
    amountOfChosen() {
      if(!this.value) return null
      return this.value.filter(t => t.group_id === this.group.id).length
    }
  },
  methods: {
    ...mapActions('noti', [
      'info',
      'error'
    ]),
    ...mapActions('tag_view', [
      'deleteTagGroup'
    ]),
    emptyTagsFromGroup() {
      if(!this.value) return
      this.$emit('input', this.value.filter( t => t.group_id !== this.group.id ))
    },
    deleteGroup() {
      this.deleteTagGroup(this.group.id)
        .then(() => {
          this.info( this.$t('tag_group_deleted') )
        })
        .catch(e => {
          this.error(e)
        })
    }
  }
}
</script>