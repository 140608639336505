<template>
  <v-card flat>
    <v-sheet class="pa-5">
      <v-sheet class="text-h4">
        {{ $t('create_new_view') }}
      </v-sheet>
      <v-text-field
        autofocus
        v-model="new_view.tag_view_name"
        class="mt-3"
        :label="$t('tag_view_name')"
      ></v-text-field>
    </v-sheet>
    <v-card-actions>
      <v-btn
        @click="$emit('close'); clear();"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-spacer />
      <v-btn
        :disabled="!new_view.tag_view_name"
        color="primary"
        @click="save"
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    new_view: {
      tag_view_name: ""
    }
  }),
  methods: {
    ...mapActions('tag_view', [
      'createTagView'
    ]),
    clear() {
      this.new_view = {
        tag_view_name: ""
      }
    },
    save() {
      if(!this.new_view.tag_view_name) return

      this.createTagView(this.new_view)
        .then(() => {
          this.clear()
          this.$emit('close')
        })
    }
  }
}
</script>