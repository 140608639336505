<template>
  <v-btn text x-small @click="showTagAdder = true">
    <v-icon class="mr-2" :size="20">mdi-tag-outline</v-icon>
    {{$t('analysis.add_tags')}} 

    <span class="ml-1 primary--text" v-if="some">
      {{ value.length }}
    </span>
    <span class="ml-1" v-else>
      (T)
    </span>

    <v-icon
      size="15"
      v-if="some"
      class="ml-2"
      @click.stop="$emit('input', [])"
    >
      mdi-close
    </v-icon>

    <TagViewsDialog
      mode="choose"
      :show="showTagAdder"
      v-if="showTagAdder"
      @input="$emit('input', $event)"
      :value="value"
      @close="showTagAdder = false"
      @cancel="showTagAdder = false"
      @set="showTagAdder = false"
    ></TagViewsDialog>
  </v-btn>
</template>

<script>
import TagViewsDialog from '@/components/tag_views/TagViewsDialog.vue'

export default {
  props: ['value'],
  components: { TagViewsDialog },
  shortcuts: {    
    t: function() {
      this.showTagAdder = !this.showTagAdder
    },
  },
  data: () => ({
    showTagAdder: false
  }),
  computed: {
    some() {
      return this.value && this.value.length
    }
  }
}
</script>