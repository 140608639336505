<template>
  <div class="d-flex align-center my-1">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          small
          outlined
        >
          <v-icon>
            mdi-plus
          </v-icon>
          {{ $t('add_hotkey') }}
        </v-btn>

      </template>
      <v-card class="pa-5">
        <v-btn
          v-for="btn in button_types"
          outlined
          :key="btn.value"
          small
          class="ml-2"
        >
          <v-icon class="mr-2" size="15">
            {{ btn.icon }}
          </v-icon>
          {{ btn.text }}
        </v-btn>
      </v-card>
    </v-menu>
    <!-- <v-select
      class="pr-5"
      dense
      solo
      hide-details
    ></v-select>
    <v-select
      class="pr-5"
      dense
      solo
      hide-details
    ></v-select> -->
    <FormHotkey v-if="false" :box_only="true" v-model="hotkeyObj"></FormHotkey>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormHotkey from '@/components/FormHotkey.vue'

export default {
  components: {
    FormHotkey
  },
  data: () => ({
    hotkeyObj: {
      hotkey: null,
      shift: null
    }
  }),
  computed: {
    ...mapGetters('hotkey', [
      'hotkeys',
      'loading_hotkeys'
    ]),
    ...mapGetters('tag_view', [
      'tag_views',
      'tag_groups',
      'tags'
    ]),
    button_types() {
      return [
        { text: this.$t('tag'), value: 'tag', icon: 'mdi-tag-outline' },
        { text: this.$t('automation'), value: 'automation', icon: 'mdi-auto-mode' }
      ]
    },
    general_hotkeys() {
      return [
        { text: this.$t('hide_map'), value: 'hide_map' }
      ]
    }
  }
}
</script>