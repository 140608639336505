<template>
  <v-sheet
    class="d-flex align-center"
    style="max-width: 100%;"
  >
    <div class="d-flex align-center" style="width: 100%;">
      <div class="d-flex pa-2 pb-4" style="overflow-x: scroll;">
        <!-- LOADING SPINNER -->
        <v-sheet v-if="loading_tag_views" class="d-flex align-center">
          <v-progress-circular
            indeterminate
            color="grey"
            size="20"
          >
          </v-progress-circular>
        </v-sheet>
        <!-- VIEWS -->
        <draggable
          :value="tag_views"
          v-bind="drag_options"
          @input="reorderViews($event)"
          class="d-flex flex-row"
          @start="dragging = true"
          @end="dragging = false"
        >
          <tag-view-header-block
            v-for="(view, i) in tag_views"
            :key="view.id + ' ' + i"
            :view="view"
            :i="i"
            :dragging="dragging"
            :value="value"
          ></tag-view-header-block>
        </draggable>
        <!-- ADD VIEW -->
        <v-btn
          v-if="mode === 'edit'"
          @click="creating_new_view = true"
          small
          text
          style="margin-top: 2px;"
          v-tooltip="$t('add_tag_view')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <!-- Automations and rules -->
      <div v-if="mode !== 'clip'">
        <div v-if="!$vuetify.breakpoint.mobile && !sidebar">
          <v-btn
            text
            small
            @click="editingHotkeys = true"
          >
            <v-icon class="mr-3">mdi-keyboard-outline</v-icon>
            {{ $t('hotkeys') }}
          </v-btn>
          <v-btn
            text
            small
          >
            <v-icon class="mr-3">mdi-auto-mode</v-icon>
            {{ $t('automations') }}
          </v-btn>
        </div>
        <v-menu v-else>
          <template v-slot:activator="{attrs, on}">
            <v-btn fab x-small text v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group>
              <v-list-item @click="editingHotkeys = true">
                <v-list-item-icon>
                  <v-icon>mdi-keyboard-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('hotkeys') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>
                    mdi-auto-mode
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $t('automations') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <!-- Edit btn -->
      <v-menu v-if="mode == 'clip'" offset-y dense>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            fab
            text
            class="mb-2"
            x-small
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group>
            <v-list-item @click="SET_MODE('edit')">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('edit_tags') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>

    <!-- CREATE NEW VIEW -->
    <v-dialog
      v-model="creating_new_view"
      max-width="800"
    >
      <create-tag-view
        @close="creating_new_view = false"
      ></create-tag-view>
    </v-dialog>
    <v-dialog max-width="600" v-model="editingHotkeys">
      <ManageHotkeys
        v-if="editingHotkeys"
        @close="editingHotkeys = false"
      ></ManageHotkeys>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CreateTagView from '@/components/tag_views/CreateTagView.vue'
import TagViewHeaderBlock from '@/components/tag_views/TagViewHeaderBlock.vue'
import draggable from 'vuedraggable'
import ManageHotkeys from '@/components/tag_views/hotkey/ManageHotkeys.vue'

export default {
  components: {
    CreateTagView,
    TagViewHeaderBlock,
    draggable,
    ManageHotkeys
  },
  props: ['value', 'sidebar'],
  data: () => ({
    showing_dots_view_id: null,
    menu_opened_id: null,
    creating_new_view: false,
    dragging: false,
    editingHotkeys: false
  }),
  methods: {
    ...mapActions('tag_view', [
      'reorderViews'
    ]),
    ...mapMutations('tag_view', [
      'SET_MODE'
    ])
  },
  computed: {
    ...mapGetters('tag_view', [
      'mode',
      'tags',
      'tag_groups',
      'tag_views',
      'loading_tag_views'
    ]),
    drag_options() {
      return {
          animation: 150,
          group: "views",
          disabled: this.mode !== 'edit',
          ghostClass: "ghost",
          scrollSensitivity: 20,
          scrollSpeed: 20,
          forceFallback: true,
          delay: 100
      }
    }
  }
}
</script>