<template>
  <v-container :class="{ 'pa-0': mode === 'clip' }" fluid style="height: 100%; overflow-y: scroll;">
    <v-sheet>
      <v-expand-transition>
        <div v-if="sidebar && state_mode === 'edit'">
          <v-btn @click="SET_MODE('clip')" block text small>
            {{ $t('editing_done') }}
            <v-icon color="green">mdi-check</v-icon>
          </v-btn>
        </div>
      </v-expand-transition>
      <TagViewHeader :sidebar="sidebar" :value="value" />
      <empty-state-views v-if="!loading_tag_views && !tag_views.length"></empty-state-views>
      <EmptyState
        @create="creating_group = true" class="mt-10"
        v-else-if="!loading_tag_views && !current_groups.length"
      />
      <TagGroups
        :value="value"
        @input="state_mode !== 'edit' ? $emit('input', $event) : ''"
        @click:tag="tag_clicked($event); $emit('click:tag', $event);"
        @click-right:tag="$emit('click-right:tag', $event)"
        v-else-if="!loading_tag_views && tag_views.length"
      />
      <v-btn
        v-if="tag_views.length && state_mode === 'edit'"
        @click="creating_group = true"
        class="ml-3 mt-2"
        small
        outlined
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('create_tag_group') }}
      </v-btn>


      <v-dialog max-width="800" v-model="creating_group">
        <CreateGroup
          @close="creating_group = false"
        ></CreateGroup>
      </v-dialog>
    </v-sheet>
  </v-container>
</template>

<script>
import TagViewHeader from '@/components/tag_views/TagViewHeader'
import EmptyState from '@/components/tag_views/EmptyState'
import TagGroups from '@/components/tag_views/tag_groups/TagGroups'
import CreateGroup from '@/components/tag/CreateGroup.vue'
import EmptyStateViews from '@/components/tag_views/EmptyStateViews.vue'

import { mapActions, mapGetters } from 'vuex'
import { mapMutations } from 'vuex/dist/vuex.common.js'
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: 'md',
      validator: function(value) {
        return [
          'xs',
          'sm',
          'md',
          'lg'
        ].includes(value)
      }
    },
    mode: {
      type: String,
      required: false,
      default: 'edit',
      validator: function (value) {
        return [
          'edit', // default mode - CRUD for tags, tag_groups and tag_views
          'filter', // creating filters
          'choose', // adding tags to stuff - handle chosen tags through v-model
          'clip' // coding the game
        ].includes(value)
      }
    },
    sport_id: {
      type: Number,
      required: false,
      default: null
    },
    league_id: {
      type: Number,
      required: false,
      default: null
    },
    value: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    sidebar: {
      type: Boolean,
      required: false
    }
  },
  components: {
    TagViewHeader,
    TagGroups,
    EmptyState,
    CreateGroup,
    EmptyStateViews,
  },
  data: () => ({
    creating_group: false,
  }),
  created() {
    this.SET_LEAGUE_ID(this.league_id)
    this.SET_MODE(this.mode)
    this.SET_SIZE(this.size)
    if(!this.tag_views.length) {
      this.initTagViews({ league_id: this.league_id })
    }
  },
  methods: {
    ...mapActions('tag_view', [
      'initTagViews'
    ]),
    ...mapMutations('tag_view', [
      'SET_LEAGUE_ID',
      'SET_MODE',
      'SET_SIZE'
    ]),
    tag_clicked(tag) {
      if(['edit'].includes(this.state_mode)) return
      const tag_was_chosen = this.value.find(t => t.id === tag.id)

      if(!tag_was_chosen) this.$emit('input', this.value.concat(tag))
      else this.$emit('input', this.value.filter(t => t.id !== tag.id))
    }
  },
  computed: {
    ...mapGetters('tag_view', [
      'tag_views',
      'loading_tag_views',
      'current_groups',
      'tags'
    ]),
    ...mapGetters({
      'state_mode': 'tag_view/mode'
    })
  }
}
</script>