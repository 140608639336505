<template>
  <v-sheet
    style="width: 100%;"
  >
    <draggable
      :value="tags"
      @input="reorder($event)"
      class="d-flex flex-wrap"
      v-bind="drag_options"
    >
      <Tag
        :value="value"
        @click:tag="$emit('click:tag', $event)"
        @click-right:tag="$emit('click-right:tag', $event)"
        v-for="(tag, i) in tags"
        :ref="`tag_${i}`"
        :key="tag.id + '_' + group.id + '_group_tag'"
        :tag="tag"
        :action="group.action_type === 'action'"
      ></Tag>
    </draggable>
    <v-btn
      text
      outlined
      small
      style="margin-top: 7.5px;"
      @click="adding_tag = true"
      v-if="mode === 'edit'"
    >
      <v-icon style="opacity: .5">mdi-plus</v-icon>
      <v-sheet class="ml-3" style="opacity: .5">
        {{ $t('add_tag') }}
      </v-sheet>
    </v-btn>
    <v-dialog width="800" v-model="adding_tag">
      <EditAndCreateTag
        :group_id="group.id"
        v-if="adding_tag"
        @close="adding_tag = false"
        @close-and-new="closeAndCreateNew()"
      ></EditAndCreateTag>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Tag from '@/components/tag_views/tags/Tag.vue'
import EditAndCreateTag from '@/components/tag_views/tags/EditAndCreateTag'
import draggable from 'vuedraggable'

export default {
  props: ['group', 'value'],
  components: {
    Tag,
    draggable,
    EditAndCreateTag
  },
  data: () => ({
    adding_tag: false
  }),
  methods: {
    ...mapActions('tag_view', [
      'reorderTags',
      'setEditingTag'
    ]),
    reorder(tags) {
      this.reorderTags({ tags, group_id: this.group.id })
    },
    closeAndCreateNew() {
      this.adding_tag = false
      this.$nextTick(() => {
        setTimeout(() => {
          this.adding_tag = true
        }, 200)
      })
    }
  },
  computed: {
    ...mapGetters('tag_view', [
      'group_tags',
      'mode'
    ]),
    tags() {
      return this.group_tags(this.group.id)
    },
    drag_options() {
      return {
          animation: 150,
          group: "views",
          ghostClass: "ghost",
          scrollSensitivity: 20,
          scrollSpeed: 20,
          disabled: this.mode !== 'edit'
      }
    }
  }
}
</script>