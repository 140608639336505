var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row align-center",style:(`
    height: ${_vm.size}px; 
    width: ${_vm.size}px; 
    background-color: ${_vm.shape_color}; 
    border: ${_vm.border};
    ${_vm.shape_css};
    cursor: pointer;
  `),on:{"click":function($event){return _vm.$emit('point-clicked', _vm.id)}}},[(!_vm.color)?_c('div',{style:(`width: ${_vm.size}px; height: 3px; background-color: red; align-self: center; justify-self: center;`)}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }