<template>
  <v-sheet>
    <draggable
      :value="current_groups"
      v-bind="drag_options"
      @input="reorder($event)"
      handle=".handle"
      @start="dragging_group = true"
      @end="dragging_group = false"
    >
      <v-sheet
        v-for="group in current_groups"
        :key="group.id + '_group'"
      >
        <TagGroup
          :value="value"
          @input="$emit('input', $event)"
          @click:tag="$emit('click:tag', $event)"
          @click-right:tag="$emit('click-right:tag', $event)"
          :group="group"
          :dragging="dragging_group"
        />
      </v-sheet>
    </draggable>
  </v-sheet>
</template>

<script>
import TagGroup from '@/components/tag_views/tag_groups/TagGroup'
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  props: ['value'],
  components: {
    TagGroup,
    draggable
  },
  data: () => ({
    dragging_group: false
  }),
  methods: {
    ...mapActions('tag_view', [
      'reorderGroups'
    ]),
    reorder(groups) {
      this.reorderGroups({ groups, view_id: this.opened_tag_view_id })
    }
  },
  computed: {
    ...mapGetters('tag_view', [
      'current_groups',
      'opened_tag_view_id',
      'mode'
    ]),
    drag_options() {
      return {
          animation: 100,
          group: "groups",
          disabled: false,
          ghostClass: "ghost-group",
          chosenClass: "chosen-group",
          scrollSensitivity: 100,
          scrollSpeed: 30,
          forceFallback: true
      }
    }
  }
}
</script>