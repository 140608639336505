<template>
  <v-dialog max-width="1000" @input="$event ? '' : $emit('close')" :value="show">
    <v-card style="height: 90vh;">
      <v-card flat style="height: calc(90vh - 55px); overflow-y: scroll;">
        <tag-views
          @input="$emit('input', $event)"
          :value="value"
          v-if="show"
          size="sm"
          :mode="mode || 'choose'"
        ></tag-views>
      </v-card>
      <v-card-actions>
        <v-btn @click="$emit('cancel'); $emit('close');">
          {{$t('cancel')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('set')" color="primary">
          {{ $t('ok') }}
          <v-icon class="ml-2">mdi-keyboard-return</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TagViews from '@/components/tag_views/TagViews.vue'
import { mapGetters } from 'vuex'
export default {
  name: "TagViewsDialog",
  props: ['mode', 'show', 'value'],
  components: {
    TagViews
  },
  shortcuts: {
    enter: function() {
      if(this.keybinds) {
        this.$emit('set')
        return false
      }
    }
  },
  computed: {
    ...mapGetters([
      'keybinds'
    ])
  }
}
</script>