<template>
  <v-sheet v-if="video">
    <!-- {{ league_config }} -->
    <!-- {{ video }} -->
    <!-- {{ game_players }} -->
    <v-sheet
      v-for="side in ['home', 'away']"
      :key="side + '_lineup'"
    >
      <v-sheet class="d-flex justify-start align-center">
        <v-img 
          :src="video[`${side}_club_logo_url`]" 
          style="max-width: 40px;" 
          height="30" 
          width="30" 
          contain
          class="mr-4"
        />
        <v-sheet class="text-button">
          {{ video[`${side}_club_name`] }} - {{ side }}
        </v-sheet>
      </v-sheet>
      <draggable
        :value="game_players[side]"
        handle=".player-handle"
        @input="changeSideOrder({ side, players: $event, number_follows_player: lineups_config.numbers_attached_to_players })"
      >
        <player-adder
          v-for="(player, i) in game_players[side]" 
          :key="i + '_' + side + '_player'"
          :i="i"
          :side="side"
          :player="player"
        ></player-adder>
      </draggable>
      <v-sheet>
        <v-btn class="my-3" block text x-small @click="addEmpty({ 
          side, 
          league_club_id: side === 'home' ? video.home_club_id : video.away_club_id, 
          league_id: video.league_id, 
          league_game_id: video.id
        })">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-sheet>
    </v-sheet>
    <v-sheet 
      class="d-flex mt-4"
    >
      <v-btn 
        v-if="lineups_config.fetch_url" @click="fetchLineups"
        outlined
      >
        Fetch lineups
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        color="primary"
        :loading="saving_players"
        @click="save"
      >
        Save
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getValueFromPath } from '@/utils/config/configHelper.js'
import axios from 'axios'
import draggable from 'vuedraggable'
import PlayerAdder from './PlayerAdder.vue'

// let url = `https://api.pesistulokset.fi/api/v1/public/match?id=${this.gameData.pesis_id}&apikey=wRX0tTke3DZ8RLKAMntjZ81LwgNQuSN9`

export default {
  data: () => ({
  }),
  created() {
  },
  components: {
    draggable,
    PlayerAdder
  },
  methods: {
    ...mapActions('noti', [
      'error',
      'info',
      'success'
    ]),
    ...mapMutations('game', [
      'SET_GAME_PLAYERS'
    ]),
    ...mapActions('game', [
      'changeSideOrder',
      'addEmpty',
      'savePlayers',
      'initGamePlayers',
      'checkPlayerExists'
    ]),
    save() {
      this.savePlayers({ league_id: this.video.league_id, league_game_id: this.video.id })
        .then(() => {
          this.success('saved :)')
        })
        .catch(e => {
          this.error(e)
        })
    },
    fetchLineups() {
      const arr = this.lineups_config.fetch_url.split('$')
      const formed_url = arr[0] + this.video.external_service_id + arr[1]

      axios.get(formed_url)
        .then(e => {
          this.addPlayers(e.data)
          this.info('lineup fetched')
        })
        .catch(e => {
          this.error(e)
        })
    },
    addPlayers(data) {
      const fetch_format = this.lineups_config.fetch_format
      let arr_home = getValueFromPath(data, fetch_format.home_array)
      let arr_away = getValueFromPath(data, fetch_format.away_array)

      arr_home = arr_home.map(player => {
        return {
          num: getValueFromPath(player, fetch_format.num),
          pos: getValueFromPath(player, fetch_format.pos),
          picture_url: getValueFromPath(player, fetch_format.picture_url),
          external_service_id: getValueFromPath(player, fetch_format.external_service_id),
          player_name: getValueFromPath(player, fetch_format.player_name),
          league_game_id: this.$route.params.id,
          league_id: this.league.id,
          league_club_id: this.video.home_club_id
        }
      })

      arr_away = arr_away.map(player => {
        return {
          num: getValueFromPath(player, fetch_format.num),
          pos: getValueFromPath(player, fetch_format.pos),
          picture_url: getValueFromPath(player, fetch_format.picture_url),
          external_service_id: getValueFromPath(player, fetch_format.external_service_id),
          player_name: getValueFromPath(player, fetch_format.player_name),
          league_game_id: this.$route.params.id,
          league_id: this.league.id,
          league_club_id: this.video.away_club_id
        }
      })

      this.SET_GAME_PLAYERS({
        home: arr_home,
        away: arr_away
      })

      this.$nextTick(() => {
        this.game_players.home.forEach((p) => {
          this.checkPlayerExists({ name: p.player_name, side: 'home' })
        })

        this.game_players.away.forEach((p) => {
          this.checkPlayerExists({ name: p.player_name, side: 'away' })
        })
      })
    }
  },
  computed: {
    ...mapGetters('videos', [
      'video'
    ]),
    ...mapGetters('game', [
      'lineups',
      'game_players',
      'players_by_club_id',
      'saving_players'
    ]),
    ...mapGetters('admin', [
      'leagueById'
    ]),
    league() {
      return this.leagueById(this.video ? this.video.league_id : null) || "{}"
    },
    league_config() {
      if(this.league && this.league.configuration) return JSON.parse(this.league?.configuration)
      return {}
    },
    lineups_config() {
      if(this.league && this.league.configuration) return JSON.parse(this.league?.configuration)?.lineups
      return {}
    }
  }
}
</script>