import { render, staticRenderFns } from "./GameCode.vue?vue&type=template&id=40477e51&scoped=true"
import script from "./GameCode.vue?vue&type=script&lang=js"
export * from "./GameCode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40477e51",
  null
  
)

export default component.exports