<template>
  <div class="d-flex flex-column justify-space-between" style="position: relative; height: calc(100vh - 60px) !important; overflow-y: scroll;">
    <div style="overflow-y: scroll;">
      <tag-views
        @click:tag="mode !== 'edit' ? tagClicked($event) : ''"
        @click-right:tag="REMOVE_TAG($event.id)"
        :value="tags"
        mode="clip"
        size="xs"
        :sidebar="true"
      />
    </div>
    <!-- <div
      v-for="(flyer, i) in flyers"
      :id="'flyer-' + i"
      :key="'flyer-' + i"
    >
      <v-icon>mdi-tag-outline</v-icon>
    </div> -->
    <v-card class="py-2" elevation="5" style="width: 100%;" v-if="mode === 'clip'">
      <div class="d-flex justify-center">
        <v-btn outlined v-tooltip="`${$t('show_map_carousel')} (M)`" small @click="showMap = !showMap; showTimer = false;">
          <v-icon v-if="!showMap">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
          <v-icon>mdi-map-outline</v-icon>
        </v-btn>
        <v-btn outlined class="ml-2" v-tooltip="`${$t('show_map_carousel')} (M)`" small @click="showTimer = !showTimer; showMap = false">
          <v-icon v-if="!showTimer">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
          <v-icon>mdi-clock-outline</v-icon>
        </v-btn>
      </div>
      <map-carousel
        v-show="showMap"
        :not_hidable="true"
        :hide_showable_shapes="true"
        ref="carousel"
        :color="'blue'"
        @input="handleCarouselInput($event)"
        :value="points"
      ></map-carousel>
      <Timer
        :flat="true"
        v-show="showTimer"
      ></Timer>
    </v-card>
  </div>
</template>

<script>
import TagViews from '@/components/tag_views/TagViews.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import MapCarousel from '@/components/maps/MapCarousel.vue'
import Timer from '@/components/video/analysisBar/Timer.vue'

export default {
  components: { TagViews, MapCarousel, Timer },
  created() {
    this.SET_VIDEO_ID(this.$route.params.id)
  },
  data: () => ({
    clips: [
      {
        tag_id: 123,
        starttime: 1001.23293,
        endtime: 1424.24914 // if undefined -> keep going
      }
    ],
    // flyers: [],
    points: [],
    showMap: false,
    showTimer: false
  }),
  methods: {
    ...mapActions('gamecode', [
      'tagClicked',
      'handleTimeFurthering'
    ]),
    ...mapMutations('gamecode', [
      'SET_TAGS',
      'REMOVE_TAG',
      'SET_VIDEO_ID'
    ]),
    createClip(tag) {
      // this.flyers.push()
      // const source = document.getElementById(`tag-${tag.id}`)
      // const sourceRect = source.getBoundingClientRect()

      // const target = document.getElementById("side-bar-video-clip")
      // const targetRect = target.getBoundingClientRect()

      // console.log(sourceRect)
      // console.log(targetRect)

      console.log(tag)
    }
  },
  computed: {
    ...mapGetters('tag_view', [
      'group_by_tag_id',
      'mode'
    ]),
    ...mapGetters('player', [
      'duration',
      'currentTime'
    ]),
    ...mapGetters('gamecode', [
      'tags'
    ])
  },
  watch: {
    'currentTime': {
      handler: function(newVal) {
        this.handleTimeFurthering(newVal)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>