<template>
  <div 
    :style="`
      height: ${size}px; 
      width: ${size}px; 
      background-color: ${shape_color}; 
      border: ${border};
      ${shape_css};
      cursor: pointer;
    `"
    @click="$emit('point-clicked', id)"
    class="d-flex flex-row align-center"
  >
    <div v-if="!color" :style="`width: ${size}px; height: 3px; background-color: red; align-self: center; justify-self: center;`"></div>
  </div>
</template>

<script>
export default {
  props: ['shape', 'color', 'size', 'id'],
  computed: {
    shape_css() {
      let shape = this.shape
      if(!shape) shape = 'circle'
      switch(shape) {
        case 'circle':
          return `border-radius: 50%; transform: scale(1.20);`
        case 'diamond':
          return `transform: rotate(45deg) scale(0.92);`
        case 'rounded':
          return `border-radius: 20%;`
      }

      return ''
    },
    shape_color() {
      return this.color || '#FFFFFF'
    },
    border() {
      return '1px dashed black;'
    }
  }
}
</script>