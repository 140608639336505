<template>
  <div>
    <div v-if="!box_only" class="text-button">
      Hotkey
    </div>
    <div @click="$refs.hotkeyFormer.focus()" tabindex="0" class="hotkey-former glowing-border mx-auto d-flex align-center justify-center" ref="hotkeyFormer">
      <div v-if="!loading">
        <v-icon v-if="value.shift">mdi-apple-keyboard-shift</v-icon>
        {{ value.hotkey || '—' }}
      </div>
      <div v-else>
        <v-progress-circular color="secondary" indeterminate />
      </div>
    </div>
    <div v-if="!box_only" style="font-size: 10px; color: grey;">
      Protip: Use Shift
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'box_only', 'loading'],
  shortcuts: {
    keydown: function(e) {
      if (this.$refs.hotkeyFormer !== document.activeElement) return
      if (e.keyCode === 9) return true // Tab
      e.preventDefault()
      if (e.keyCode === 13) return false // Enter
      if (e.keyCode === 8) { // Backspace
        this.$emit('input', { shift: null, hotkey: null })
        return
      }
      if (e.keyCode === 20) return // Capslock

      const isAlpha = /^[a-zA-Z]$/.test(e.key);
      const isNumber = e.keyCode >= 48 && e.keyCode <= 57; // 0-9 keyCodes

      // Only process if it's alphanumeric
      const isAlphaNumeric = isAlpha || isNumber;
      if (!isAlphaNumeric) return

      this.$refs.hotkeyFormer.blur()

      this.handleKey(e)
      return false
    }
  },
  methods: {
    handleKey(e) {
      let hotkey = e.key.toUpperCase();

      // If the key is a number (0-9) on the top row, use `keyCode` to capture the number directly
      if (e.keyCode >= 48 && e.keyCode <= 57) {
        hotkey = String.fromCharCode(e.keyCode); // Convert keyCode to the corresponding number character
      }

      this.$emit('input', { shift: e.shiftKey, hotkey });
    }
  }
}
</script>

<style lang="scss" scoped>
.hotkey-former {
  height: 50px;
  width: 70px;
  background-color: rgb(232, 232, 232);
  border-radius: 8px; /* Optional for rounded corners */

  // &:focus {
  //   border: 5px solid blue;
  // }
}

.glowing-border:focus {
  border: 2px solid #007bff; /* Main border color */
  background-color: white; /* Background color */

  /* Glowing effect using box-shadow */
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.4);
  transition: box-shadow 0.3s ease-in-out;
}
</style>