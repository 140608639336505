<template>
  <v-sheet
    class="mr-1 mt-1 d-flex align-center justify-center tag"
    :style="tag_style"
    style="position: relative;"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
    @click="$emit('click:tag', tag)"
    @contextmenu.prevent="$emit('click-right:tag', tag)"
    :id="'tag-' + tag.id"
  >
    <div
      v-if="show_record"
      class="blink"
      style="
        height: 12px;
        width: 12px;
        background-color: red;
        position: absolute;
        left: 6px;
        border-radius: 50%;
        border: 1px solid black;
      "
    ></div>
    <div class="tag-checkbox" v-if="show_check_box">
      <v-icon class="tag-checkbox-icon" style="margin-top: -2px;" size="15" v-if="is_chosen" color="primary">mdi-check</v-icon>
    </div>
    <div :class="{ 'justify-self-end': show_check_box }" :style="`flex-grow: ${show_check_box ? '1' : '0'};`">
      {{ truncate(tag.tag_name, mode === 'clip' ? ( tag.hotkey ? 14 : 20 ) : 20) }}
    </div>
    <v-chip
      style="cursor: pointer;"
      class="px-1 ml-2"
      x-small
      label
      v-if="(!hovering || mode === 'clip') && !show_check_box && !!tag.hotkey"
    >
      <v-icon
        v-if="has_shift && (!hovering || mode !== 'edit')"
        size="16"
        class="pa-0"
      >mdi-apple-keyboard-shift</v-icon>
      <div v-if="!hovering || mode !== 'edit'">
        {{ hotkey_char }}
      </div>
    </v-chip>
    <v-menu v-if="this.mode === 'edit'" @input="menu_opened = $event" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-expand-x-transition>
          <v-btn
            style="justify-self: end;"
            v-show="hovering || menu_opened"
            v-on="on"
            v-bind="attrs"
            class="ml-1"
            text
            fab
            x-small
          >
            <v-icon :color="action ? 'white' : 'black'">mdi-dots-vertical</v-icon>
          </v-btn>
        </v-expand-x-transition>
      </template>
      <v-card>
        <v-list dense>
          <v-list-item-group>
            <v-list-item @click="setEditingTag({ tag_id: tag.id, val: true })">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('edit') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleting = true">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('delete') }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>

    <!-- <v-progress-linear
      :style="`width: ${sizes[size] - 5}; position: absolute; bottom: -4px; border-radius: 3px;`"
      color="error"
      v-if="is_enduring"
      indeterminate
    />
    <v-progress-linear
      :style="`width: ${sizes[size] - 5}; position: absolute; bottom: -4px; border-radius: 3px;`"
      color="error"
      :value=""
      v-else-if="endtime"
    /> -->

    <confirmation-dialog
      v-if="deleting"
      :show="deleting"
      :text="`${$t('delete')} ${tag.tag_name}?`"
      :btnText="$t('delete')"
      :subText="$t('delete_tag_long')"
      btnColor="error"
      @decline="deleting = false"
      @accept="startDeleteTag()"
    ></confirmation-dialog>

    <v-dialog v-if="tag.editing" max-width="800" @input="setEditingTag({ tag_id: tag.id, val: $event })" :value="tag.editing">
      <EditAndCreateTag
        @close="closeEditing()"
        @close-and-new="closeEditing()"
        :editing_tag="tag"
      ></EditAndCreateTag>
    </v-dialog>
  </v-sheet>
</template>

<script>
import ConfirmationDialog from '@/components/ConfirmationDialog'
import EditAndCreateTag from '@/components/tag_views/tags/EditAndCreateTag'
import color from '@/mixins/color'

import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    ConfirmationDialog,
    EditAndCreateTag
  },
  mixins: [ color ],
  props: ['tag', 'action', 'value'],
  data: () => ({
    hovering: false,
    menu_opened: false,
    deleting: false,
    sizes: {
      xs: { height: 30, width: 135.2 },
      sm: { height: 30, width: 155 },
      md: { height: 40, width: 180 }
    },
    fontSizes: {
      xs: '.8rem',
      sm: '.8rem',
      md: '.9rem'
    }
  }),
  methods: {
    ...mapActions('tag_view', [
      'deleteTag',
      'setEditingTag'
    ]),
    ...mapActions('noti', [
      'info',
      'error'
    ]),
    closeAndEditNext() {
      this.setEditingTag({ tag_id: this.tag.id, val: false })
      this.$emit('edit-next');
    },
    closeEditing() {
      this.setEditingTag({ tag_id: this.tag.id, val: false })
    },
    editTag() {
      this.setEditingTag({ tag_id: this.tag.id, val: true })
    },
    truncate(str, n) { return str.length > n ? str.slice(0, n) + '' : str },
    startDeleteTag() {
      this.deleteTag(this.tag.id)
        .then(() => {
          this.info(this.$t('tag_deleted'))
        })
        .catch(e => {
          this.error(e)
        })
    }
  },
  computed: {
    ...mapGetters('tag_view', [
      'size',
      'mode'
    ]),
    ...mapGetters('player', [
      'accurateCurrentTime',
      'currentTime'
    ]),
    show_check_box() {
      return !['edit', 'clip'].includes(this.mode)
    },
    found_tag() {
      // Find the tag with matching id in this.value (or return null if not found)
      return this.value?.find(t => t.id === this.tag.id) || null;
    },
    is_chosen() {
      // Check if the tag was found
      return !!this.found_tag;
    },
    is_enduring() {
      // Check if the found tag has the 'enduring' property set to true
      return this.found_tag?.enduring || false;
    },
    starttime() {
      return this.found_tag?.starttime;
    },
    endtime() {
      return this.found_tag?.endtime;
    },
    has_shift() {
      if(!this.tag.hotkey) return false
      return this.tag.shift
    },
    amount() {
      if(!this.endtime) return 0
      return (this.accurateCurrentTime - this.starttime) / (this.endtime - this.starttime) * 100
    },
    hotkey_char() {
      return this.tag.hotkey
    },
    tag_color() {
      return this.tag.map_color || this.$vuetify.theme.currentTheme.primary;
      // return this.tag.map_color || '#AAAAAA';
    },
    show_record() {
      return this.action && this.is_chosen;
    },
    darkened_tag_color() {
      return this.adjustHexColor(this.tag_color, -40);
    },
    tag_style() {
      let bgColor;
      if(this.action) {
        bgColor = this.is_chosen ? this.darkened_tag_color : this.tag_color;
      } else {
        bgColor = this.is_chosen ? this.adjustHexColor(this.tag_color, 80) : "white";
      }

      let textColor = !this.is_chosen && !this.action ? this.tag_color : 'white';
      let border = this.action ? '' : '2px solid ' + this.tag_color;

      let boxShadow = this.is_chosen ? `0px 4px 4px ${this.tag_color} 0px` : ''

      let background = (this.is_enduring || !this.action || this.mode !== 'clip') ? null : `linear-gradient(to right, ${this.darkened_tag_color} ${this.amount}%, ${this.tag_color} ${this.amount}%)`

      return {
        background,
        backgroundColor: bgColor,
        whiteSpace: 'nowrap',
        width: `${this.sizes[this.size]['width']}px`,
        height: `${this.sizes[this.size]['height']}px`,
        borderRadius: ['choose', 'filter'].includes(this.mode) ? this.sizes[this.size]['height'] / 2 + 'px' : '5px',
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: this.fontSizes[this.size],
        color: textColor,
        border: border,
        boxShadow
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// Define your base color variables
$action-color: #2D57FB;
$metadata-color: #6a87fc;

// Define the darkened versions
$darkened-action-color: darken($action-color, 25%);
$darkened-metadata-color: darken($metadata-color, 25%);

.tag {
  transition-duration: .1s;

  // Action tag background color
  &--action {
    // background-color: $action-color;
    // color: white;
  }

  // Metadata tag background color
  &--metadata {
    // background-color: $metadata-color;
    // color: white;
  }

  // Darken the background when the tag--chosen class is also applied
  &--chosen.tag--action {
    // background-color: $darkened-action-color;
  }

  &--chosen.tag--metadata {
    // background-color: $darkened-metadata-color;
  }

  &-checkbox {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, .25);
    background-color: white;
    filter: brightness(100%);
    margin-left: 6px;
    flex-shrink: 1;
    height: 18px;
    width: 18px;
    border-radius: 50%;
  }
}

.blink {
  animation: blink-animation 1s steps(30, start) infinite;
  -webkit-animation: blink-animation 1s steps(30, start) infinite;
}
@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>