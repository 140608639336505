<template>
  <v-list-item
    @click.stop="choose()"
  >
    <v-list-item-icon v-if="!loading">
      <v-icon>mdi-plus</v-icon>
    </v-list-item-icon>
    <v-list-item-title v-if="!loading">
      {{$t('analysis.add_to_folder')}}
    </v-list-item-title>
    <v-progress-circular class="mx-auto" size="20" indeterminate v-else></v-progress-circular>

    <choose-folder
      v-model="folders"
      @cancel="choosing = false; folders = []"
      @close="choosing = false; folders = []"
      @set="choosing = false; handleSave()"
      v-if="choosing"
      :visible="choosing"
    ></choose-folder>
  </v-list-item>
</template>

<script>
import ChooseFolder from "@/components/folder/ChooseFolder.vue"
import { mapActions } from 'vuex'

export default {
  props: ['id_type', 'type', 'id', 'folder_name'],
  components: { ChooseFolder },
  data: () => ({
    choosing: false,
    loading: false,
    folders: [],
    original_folders: []
  }),
  methods: {
    ...mapActions('noti', [
      'error',
      'success'
    ]),
    ...mapActions('folder', [
      'itemFolders',
      'addFolder',
      'removeItemFolders'
    ]),
    async handleSave() {
      try {
        await this.removeItemFolders({ id_type: this.id_type, id: this.id })
      } catch(e) {
        this.error(e)
        return
      }

      const folder_promises = this.folders.map(folder => {
        let add_folder = {
          parent: folder.id,
          name: this.folder_name,
          type: this.type
        }
        add_folder[this.id_type] = this.id
        return this.addFolder(add_folder)
      })

      Promise.all(folder_promises)
        .then(() => {
          this.success(this.$t('folders_modified'))
          this.choosing = false
        })
        .catch(e => {
          this.error(e)
        })
    },
    choose() {
      this.loading = true

      this.itemFolders({ id_type: this.id_type, id: this.id })
        .then(e => {
          this.folders = e
          this.original_folders = e
          this.choosing = true
        })
        .catch(e => {
          this.error(e)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>