<template>
  <div class="mr-2">
    <v-select
      dense
      style="width: 87px; z-index: 10;"
      solo
      class="mt-0"
      :items="items"
      @input="changePlaybackRate($event)"
      :value="playbackRate"
      :hide-details="true"
      :disabled="presentationDisabled"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['presentation'],
  computed: {
    ...mapGetters('player', [
      'playbackRate',
      'rates'
    ]),
    ...mapGetters('presentation', [
      'activeType'
    ]),
    items() {
      return this.rates.map(i => {
        return {
          value: i,
          text: i + 'x'
        }
      })
    },
    presentationDisabled() {
      if(this.presentation) {
        return !(this.activeType == 'time' || this.activeType =='clip')
      }
      return false
    }
  },
  methods: {
    ...mapActions('player', [
      'changePlaybackRate'
    ])
  }
}
</script>