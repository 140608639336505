<template>
  <v-sheet class="d-flex align-center justify-space-between mt-1">
    <div v-if="hotkey" class="text-button">
      <v-icon v-if="tag" class="mr-2">mdi-tag-outline</v-icon>
      <v-icon v-else>mdi-select-all</v-icon>
      {{ title }}
    </div>
    <div class="d-flex align-center">
      <FormHotkey
        @input="handleInput($event)"
        :loading="loading"
        :box_only="true"
        :value="{ hotkey: hotkey.hotkey, shift: hotkey.shift }"
      ></FormHotkey>
      <v-btn
        v-if="removable"
        fab
        text
        x-small
        class="ml-1"
        color="grey"
        @click="startRemoveHotkey()"
      >
        <v-icon v-if="!removing" size="15">mdi-close</v-icon>
        <v-progress-circular
          v-else
          indeterminate
          size="15"
        />
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormHotkey from '@/components/FormHotkey.vue'

export default {
  props: ['hotkey', 'removable'],
  // actions, empty map, toggle_map, toggle_timer,
  // next clip, previous clip, delete_latest_clip
  components: {
    FormHotkey
  },
  data: () => ({
    loading: false,
    removing: false
  }),
  methods: {
    ...mapActions('hotkey', [
      'updateHotkey',
      'removeHotkey'
    ]),
    startRemoveHotkey() {
      this.removing = true

      this.removeHotkey({ hotkey_id: this.hotkey.id, tag_id: this.tag.id })
        .finally(() => this.removing = false)
    },
    handleInput({ hotkey, shift }) {
      this.loading = true
      this.updateHotkey({ hotkey, shift, id: this.hotkey.id })
        .finally(() => this.loading = false)
    }
  },
  computed: {
    ...mapGetters('tag_view', [
      'tag_by_id'
    ]),
    tag() {
      if(!this.hotkey.tag_id) return null
      return this.tag_by_id(this.hotkey.tag_id)
    },
    title() {
      if(this.hotkey.hotkey_action) return this.$t(this.hotkey.hotkey_action)
      if(this.tag) return this.tag.tag_name
      // Should not go here
      return ''
    }
  }

}
</script>