<template>
  <v-sheet
    class="px-4 mr-1 d-flex align-center header-item"
    style="cursor: pointer; white-space: nowrap; height: 35px; border-radius: 3px;"
    :style="`border: ${opened_tag_view_id === view.id ? '1' : '0'}px solid lightgrey; user-select: none;`"
    :elevation="opened_tag_view_id === view.id ? '2' : '1'"
    @click="openTagView(view.id)"
    @dblclick="startEditingTagName"
    @mouseover="showing_dots = true"
    @mouseleave="showing_dots = false"
  >
    <v-sheet v-if="!editing_name">
      {{ view.tag_view_name }}
    </v-sheet>
    <v-chip class="ml-2" small outlined color="primary" v-if="!editing_name && amount">
      <v-icon size="15" class="mr-2">mdi-tag-outline</v-icon>
      {{ amount }}
    </v-chip>
    <v-sheet v-if="editing_name">
      <v-text-field
        style="height: 33px;"
        ref="edit_name_field"
        class="py-0 my-0"
        hide-details
        v-prevent-keys
        autofocus
        append-icon="mdi-check"
        @blur="editing_name = false"
        @click:append.stop="updateTagViewName"
        v-model="new_name"
        dense
      ></v-text-field>
    </v-sheet>

    <!-- <v-icon class="ml-2" v-if="i === 0 && mode !== 'choose'">mdi-home-outline</v-icon> -->

    <v-menu offset-y @input="menu_opened = $event">
      <template v-slot:activator="{ on, attrs }">
        <v-expand-x-transition>
          <v-btn
            v-show="(showing_dots || menu_opened) && !dragging && mode === 'edit'"
            fab
            v-on="on"
            v-bind="attrs"
            text
            class="header-item-dots ml-3"
            x-small
            @click.stop
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-expand-x-transition>
      </template>
      <v-card>
        <v-list dense>
          <v-list-item-group>
            <v-list-item @click="startEditingTagName">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('change_name') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleting = true">
              <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
              <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>

    <confirmation-dialog
      v-if="deleting"
      :show="deleting"
      :text="`${$t('delete')} ${view.tag_view_name}?`"
      :btnText="$t('delete')"
      :subText="$t('delete_view_long')"
      btnColor="error"
      @decline="deleting = false"
      @accept="deleteView()"
    ></confirmation-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
export default {
  components: { ConfirmationDialog },
  props: ['view', 'i', 'dragging', 'value'],
  shortcuts: {
    keydown: function(e) {
      if(e.keyCode === 13 && this.editing_name) this.updateTagViewName()
    }
  },
  data: () => ({
    menu_opened: false,
    showing_dots: false,
    editing_name: false,
    new_name: '',
    deleting: false
  }),
  methods: {
    ...mapActions('tag_view', [
      'openTagView',
      'updateTagView',
      'deleteTagView'
    ]),
    ...mapActions('noti', [
      'success',
      'error',
      'info'
    ]),
    deleteView() {
      this.deleteTagView(this.view.id)
        .then(() => {
          this.info(this.$t('tag_view_deleted'))
        })
        .catch(e => {
          this.error(e)
        })
    },
    startEditingTagName() {
      this.editing_name = true; 
      this.new_name = this.view.tag_view_name;
    },
    updateTagViewName() {
      if(!this.new_name || this.new_name == this.view.tag_view_name) {
        this.editing_name = false
        return
      } 

      this.updateTagView({ id: this.view.id, tag_view_name: this.new_name })
        .then(() => {
          this.editing_name = false
          this.success(this.$t('tag_view_update_success'))
        })
    }
  },
  computed: {
    ...mapGetters('tag_view', [
      'groups_by_view_id',
      'opened_tag_view_id',
      'mode'
    ]),
    amount() {
      const groups = this.groups_by_view_id(this.view.id)
      const groupSet = new Set(groups.map(g => g.id))
      const tags = this.value.filter(t => groupSet.has(t.group_id))
      return tags.length
    }
  }
}
</script>