<template>
  <div v-if="popout || !popped_out" class="ticks" style="display: flex; flex-direction: column; height: calc(100% - 80px);">
    <ChooseTemplateSelect></ChooseTemplateSelect>
    <div class="ticks-squares" style="flex-grow: 1;">
      <div style="text-align: center;">
        <v-chip v-if="popout && time_by_channel">
          {{ secondsToPretty(time_by_channel) }}
        </v-chip>
      </div>
      <tag-views
        mode="clip"
        :value="tags"
        size="xs"
        :sidebar="true"

        @click:tag="handleTagClicked($event)"
        @click-right:tag="handleTagRightClicked($event)"
      ></tag-views>
      <tag-adder
        :hideExtraButtons="true"
        :closable="false"
        :hide_actions="true"
        :tagging="false"
        :ticking="true"
        :value="tags"

        @input="handleSetTags($event)"
        @tag_right_clicked="handleTagRightClicked($event)"

        @click:tag="handleTagClicked($event)"
        @click-right:tag="handleTagRightClicked($event)"
        @edit_tags="showEditTags = true"
      ></tag-adder>
    </div>
    <v-divider></v-divider>
    <map-carousel
      :hide_showable_shapes="true"
      ref="carousel"
      class="resizable-content"
      :color="latestColorChosen"
      @input="handleCarouselInput($event)"
      :value="points"
    ></map-carousel>
    <!-- </vue-resizable> -->
    <!-- Buttons -->
    <div v-if="!popout" style="text-align: center; align-self: center;" class="mt-2">
      <!-- <v-btn :loading="savingTick" @click="saveTick()" small color="primary">
        Save (enter)
      </v-btn> -->
      <div class="mt-3">
        <!-- <v-btn @click="show_settings = true" class="mr-3" rounded x-small>
          <v-icon class="mr-2" size="14">mdi-cog-outline</v-icon>
          {{$t('settings.settings')}}
        </v-btn> -->

        <!-- UNCOMMENT WHEN FEATURE FINISHED -->
        <!-- <v-btn @click="show_tag_rules = true" class="mr-3" rounded x-small>
          <v-icon class="mr-2" size="14">mdi-arrow-u-right-top</v-icon>
          {{$t('clips.rules')}}
        </v-btn>  -->


        <v-btn @click="showEditTags = true" class="mr-3" rounded x-small>
          <v-icon class="mr-2" size="14">mdi-pencil</v-icon>
          {{$t('tags.edit_tags')}}
        </v-btn>
        <v-btn v-if="!$vuetify.breakpoint.mobile" @click="openPopout()" class="mr-3" rounded x-small>
          <v-icon class="mr-2" size="14">mdi-arrow-up</v-icon>
          {{$t('tags.open_popout')}}
        </v-btn>
      </div>
    </div>
    <ticks-settings
      :show="show_settings"
      @close="show_settings = false"
    ></ticks-settings>
    <v-dialog v-model="showEditTags" @close="show_tag_rules = false">
      <v-container fluid>
        <v-card>
          <tags></tags>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
  <div style="text-align: center;" v-else>
    <v-card flat>
      <div class="text-h6">
        {{ $t('tags.popped_out') }}
      </div>
      <v-btn class="mt-5" @click="closePopout()">
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t('come_back') }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import MapCarousel from '../maps/MapCarousel.vue'
import TagAdder from '../tag/TagAdder.vue'
import TicksSettings from '@/components/ticks/TicksSettings.vue'
import TagViews from '@/components/tag_views/TagViews.vue'

import {
  // handleChosenTagsCurrentClip,
  handleChosenTagsNextClip,
  // handleChosenTagsHotkey,
} from '@/utils/clipper/handleRules.js'
import Tags from '../../views/Tags.vue'
import time from '@/utils/player/time.js'
import ChooseTemplateSelect from '../tag/ChooseTemplateSelect.vue'

export default {
  components: {
    TagAdder, MapCarousel, TicksSettings, Tags,
    ChooseTemplateSelect, TagViews
  },
  props: ['popout'],
  mixins: [time],
  shortcuts: {
    // enter: function() {
    //   // this.saveTick()
    // },
    keydown: function(e) {
      if(this.popout) {
        const eventDetails = {
          type: e.type,
          key: e.key,
          code: e.code,
          altKey: e.altKey,
          ctrlKey: e.ctrlKey,
          shiftKey: e.shiftKey,
          keyCode: e.keyCode,
          metaKey: e.metaKey,
          isTrusted: e.isTrusted
        };
        const message = JSON.stringify({
          action: 'key_pressed_event',
          payload: eventDetails
        })
        this.channel.postMessage(message)
      }

      if(this.keybinds) {
        if(e.key == 'd') this.clipStartTime ? this.clipStartTime = null : this.setTime()
        if(e.key == 'm') this.$refs.carousel.toggleVisible()
        // this.tags = handleChosenTagsHotkey(this.tags, this.rules, this.tag_groups, undefined, e.key)
      }
      return true
    }
  },
  mounted() {
    var self = this;
    window.addEventListener('beforeunload', self.handleClosing);

    // this should prevent the tags from scrolling when pausing
    // window.onkeydown = function(e) {
    //     if(!this.keybinds) return
    //     e = e || window.event;
    //     var charCode = e.keyCode || e.which;
    //     if (charCode === 32) {
    //       e.preventDefault();
    //       return false;
    //     }

    this.SET_POPOUT(this.popout)
    this.channel = new BroadcastChannel("add_ticks_channel");
    this.channel.onmessage = (event) => {
      if(!this.popped_out && !this.popout) return
      const { data } = event
      const { action, payload } = JSON.parse(data)

      if(this.popout) {
        // run in popout window
        if(action == "close") window.close()
        if(action == "time_update") {
          this.handleTimeFurthering(payload)
        }
      } else {
        // run in main window
        switch(action) {
          case 'tag_clicked':
            this.handleTagClicked(payload)
            break;
          case 'tag_right_clicked':
            this.handleTagRightClicked(payload)
            break;
          case 'map_input':
            this.handleCarouselInput(payload)
            break;
          case 'save_tick':
            this.saveTick()
            break;
          case 'popout_was_closed':
            this.popoutClosed()
            break;
          case 'key_pressed_event':
            document.dispatchEvent(new KeyboardEvent('keydown', payload));
            break;
        }
      }

    }
  },
  data: () => ({
    show_settings: false,
    show_tag_rules: false,
    latestColorChosen: '#cccccc',
    defaultColor: '#cccccc',
    savingTick: false,
    clipStartTime: null,
    showEditTags: false,
    channel: null,
    popped_out: false
  }),
  methods: {
    ...mapActions('tag', ['iniTags']),
    ...mapActions('clip', ['addClip']),
    ...mapActions('noti', ['success']),
    ...mapActions('videos', ['toggleSideBar']),
    ...mapActions('ticks', ['handleNewTagsState', 'handleTimeFurthering', 'removeTagSilently']),
    ...mapMutations('ticks', ['SET_TAGS', 'SET_POINTS', 'SET_POPOUT', 'SET_TIME_BY_CHANNEL']),
    handleCarouselInput(e) {
      this.SET_POINTS( e.map(p => {
          return {
            ...p,
            map_base: {
              id: this.$refs.carousel.currentBaseId()
            }
          }
        }))
    },
    popoutClosed() {
      this.closePopout()
    },
    handleSetTags(tags, dont_send_forward=false) {
      this.SET_TAGS(tags)
      if(this.popout && this.channel && !dont_send_forward) {
        const message = JSON.stringify({ tags })
        this.channel.postMessage(message)
      }
    },
    handleClosing() {
      if(!this.popout) {
        this.closePopout()
      } else {
        this.tellAboutClosing()
      }
    },
    tellAboutClosing() {
      const message = JSON.stringify({
        'action': 'popout_was_closed'
      })
      this.channel.postMessage(message)
    },
    handleTagClicked(tag) {
      this.$nextTick(() => {
        // set map color
        let wasNotChosen = !!this.tags.find(t => t.id == tag.id)
        if(wasNotChosen) this.latestColorChosen = tag.map_color || this.latestColorChosen

        // this.handleSetTags(handleChosenTagsCurrentClip(this.tags, this.rules, this.tag_groups, tag))
        this.handleSetTags(this.tags)

        this.$nextTick(() => {
          this.handleNewTagsState()
        })
      })
    },
    handleTagRightClicked(tag) {
      let wasChosen = !!this.tags.find(t => t.id == tag.id)
      if(!wasChosen) return

      this.removeTagSilently(tag)
    },
    setTime() {
      this.clipStartTime = this.accurateCurrentTime
    },
    saveTick() {
      this.savingTick = true

      const isAnalysis = this.$route.path.includes('analysis')
      const isGame = this.$route.path.includes('games') || isAnalysis

      let name = ''
      this.tags.forEach((t, i) => {
        if(i == 0) name = t.tag_name
        else if(i < 4) name = name + ' - ' + t.tag_name
      })

      const add_clip = {
        leaguewide: isAnalysis,
        title: name || 'No title',
        starttime: this.clipStartTime || this.accurateCurrentTime || this.time_by_channel,
        video_id: isGame ? undefined : this.$route.params.id,
        game_id: !isGame ? undefined : this.$route.params.id,
        tags: this.tagIds,
        points: this.handledPoints,
        is_point: true,
        map_color: this.latestColorChosen,
        dont_add_to_videos: this.popout
      }

      this.addClip(add_clip)
      .then((e) => {
        if(this.isAnalysis) this.seek(this.endtime)

        // this.clipTags = handleChosenTagsNextClip(this.clipTags, this.rules, this.tag_groups)

        this.success(this.$t('analysis.clip_created') + '!')

        if(this.chosenFolder) {
          this.addCreatedClipToFolder(e.data)
        }

        this.track('Manual event added', {
          tags_amount: this.tagIds?.length,
          map_points_amount: this.handledPoints?.length
        })

        if(this.rules && this.rules.length) {
          this.tags = handleChosenTagsNextClip(this.tags, this.rules, this.tag_groups)
        } else {
          this.tags = []
        }

        this.points = []
        this.clipStartTime = null
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        this.savingTick = false
        this.latestColorChosen = this.defaultColor
      })
    },
    openPopout() {
      // const url =
      // const go_to = `${url.protocol}//${url.hostname}`
      // console.log(window.location.href)
      const windowFeatures = "width=600,height=400,resizable=yes,scrollbars=yes,status=yes";

      window.open(`${ window.origin }/#/popout/video/${this.$route.params.id}`, 'popout', windowFeatures)
      this.popped_out = true
      this.toggleSideBar(false)
    },
    closePopout() {
      const message = JSON.stringify({
        action: "close"
      })
      this.popped_out = false
      this.channel.postMessage(message)
      this.toggleSideBar(true)
    }
  },
  computed: {
    ...mapGetters('ticks', [
        'buttons',
        'autosave',
        'autosave_delay',
        'adding_map_point_saves_tag',
        'adding_tag_saves_tag',
        'tags',
        'ongoing_clips',
        'points',
        'time_by_channel'
      ]),
    ...mapGetters('player', [
      'accurateCurrentTime',
      'currentTime'
    ]),
    ...mapGetters('tag', [ 'tag_groups' ]),
    ...mapGetters('rule', [ 'rules' ]),
    ...mapGetters([ 'keybinds' ]),
      tagIds() {
        if(!this.tags) return []
        if(!this.tags.length) return []

        return this.tags.map(tag => tag.id)
      }
  },
  watch: {
    'currentTime': {
      handler(oldVal, newVal) {
        this.handleTimeFurthering(newVal)
        try {
          if(!this.popout) {
            const mes = JSON.stringify({
              action: "time_update",
              payload: newVal
            })
            this.channel.postMessage(mes)
          }
        } catch(e) {
          console.log(e)
        }
      }
    }
  },
  beforeDestroy() {
    this.handleClosing()
  }
}
</script>

<style lang="scss" scoped>
  .ticks {
    &-squares {
      overflow-y: scroll;
      height:  60vh;
      &-big {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: repeat(3, 0.7fr) repeat(5, 0.4fr);
        column-gap: 3px;
        row-gap: 3px;
        height: 400px;

        &-button {
          cursor: pointer;
          height: 100%;
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  }
</style>