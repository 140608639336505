<template>
  <v-sheet @click.stop="" class="pa-3" height="">
    <h4 style="text-align: center; font-size: 30px;">
      {{pretty}}
    </h4>
    <div
      v-for="point in time.data"
      @click="seek(point.video_time)"
      :key="point.id"
      class="d-flex align-center mb-1 time-point"
      style="cursor: pointer;"
    >
      <v-chip class="mr-3" color="primary" small>
        {{secondsToPrettyTimer(point.time_from_first)}}
      </v-chip>
      <div>
        {{point.name}}
      </div>
    </div>
    <v-row>
      {{time.description}}
    </v-row>
    <v-row style="text-align: center;">
      <v-col cols="12" style="justify-content: center;">
        <v-btn @click.stop="openTime({time, starttime: dataStarttime(time.data) - 2})">
          <v-icon>
            mdi-rotate-left
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-progress-linear
      class="mt-2"
      bottom
      :value="((currentTime - clip.starttime) / (clip.endtime - clip.starttime)) * 100"
    ></v-progress-linear> -->
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import timeMixin from '@/utils/player/time'

export default {
  props: ['time'],
  mixins: [ timeMixin ],
  methods: {
    ...mapActions('player', [
      'openTime',
      'closeTime',
      'seek'
    ])
  },
  computed: {
    ...mapGetters('player', [
      'currentTime',
      'accurateCurrentTime'
    ]),
    pretty() {
      return this.secondsToPrettyTimer(this.accurateCurrentTime - this.dataStarttime(this.time.data))
    }
  }
}
</script>

<style lang="scss" scoped>
.time-point {
  border-radius: 10px;
  transition-duration: .5s;
  &:hover {
    background-color: rgb(155, 155, 155);
  }
}
</style>