<template>
  <v-card :flat="flat" class="py-3 pt-10" :style="`position: relative; height: ${$vuetify.breakpoint.mobile ? '90%' : 'calc(100% - 53px)'};`">
    <h3
      style="
        text-align: center;
        font-size: 40px;
      "
    >
      {{
        data.length
          ? secondsToPrettyTimer(currentTime - firstTime)
          : '00:00'
      }}
    </h3>
    <div
      v-if="stage === 0"
      :style="`overflow-y: scroll; max-height: ${$vuetify.breakpoint.mobile ? 'calc(100vh - 500px)' : '100vh'}; `">
      <draggable
        :value="timenameOrdered"
        @input="timenameOrder($event)"
        handle=".handle"
        :disabled="!editing"
        v-bind="dragOptions"
      >
        <transition-group name="list" tag="div">
          <div
            v-for="timename in timenameOrdered"
            :key="'tiumename' + timename.id"
            class="d-flex px-2"
          >
            <v-btn
              block
              small
              class="my-1"
              @click="!editing ? timenameClicked(timename) : ''"
              :ripple="!editing"
              :text="editing"
              :color="timename.chosen ? 'primary' : ''"
              v-if="editing || !timename.hidden"
            >
              <span v-if="editing">
                <v-btn
                  x-small
                  v-if="!timename.hidden"
                  @click.stop="hide(timename)"
                >
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn
                  x-small
                  v-else
                  @click.stop="show(timename)"
                >
                  <v-icon>mdi-eye-off-outline</v-icon>
                </v-btn>
                <v-btn @click="deletingTimename = timename" class="ml-3" x-small color="error" text>
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </span>
              <v-icon
                v-if="!dataIncludesId(timename.id) && !editing"
                style="float: left;"
              >
                mdi-pin
              </v-icon>
              <span
                style="font-size: 20px;"
                v-if="dataIncludesId(timename.id) && !editing"
              >
                {{ secondsToPrettyTimer(timename.time) }}
              </span>
              <v-spacer></v-spacer>
              {{ handledName(timename.name) }}
              <v-icon size="20" style="color: grey;" v-if="editing" class="handle ml-2">mdi-drag-vertical</v-icon>
            </v-btn>
          </div>
        </transition-group>
      </draggable>

      <v-col cols="12">
        <v-btn
          v-if="!addingNew && editing"
          block
          class="mb-2"
          x-small
          text
          @click="addingNew = true"
        >
          <v-icon style="float: left;">
            mdi-plus
          </v-icon>
          {{ $t('add') }}
        </v-btn>
        <v-form
          v-if="addingNew && editing"
          @submit="addNewPoint($event)"
        >
          <v-text-field
            dense
            autofocus
            :placeholder="$t('analysis.timename_placeholder')"
            v-prevent-keys
            solo
            append-icon="mdi-check"
            prepend-icon="mdi-close"
            v-model="newName"
            @click:append="addNewPoint"
            @click:prepend="addingNew = false; newName = ''"
            v-click-outside="() => {addingNew = false}"
          ></v-text-field>
        </v-form>
      </v-col>
    </div>
    <div v-else style="text-align: center;">
      <div class="my-3">
        <div
          v-for="(point, i) in data"
          :key="'point' + i"
          class="mt-1"
        >
          <span>
            {{point.timename_name}}
          </span>
          <v-chip color="primary" class="ml-2">
            {{secondsToPrettyTimer(point.time_from_first)}}
          </v-chip>
        </div>
        <v-text-field
          class="mx-2 my-10"
          solo
          v-prevent-keys
          autofocus
          hide-details
          :placeholder="$t('analysis.time_title_optional')"
          v-model="title"
        ></v-text-field>
      </div>
      <choose-tag-btn
        v-model="timeTags"
      ></choose-tag-btn>
    </div>
    <div
      class="mt-2"
      style="display: flex; justify-content: space-around;"
      v-if="!editing"
    >
      <v-btn
        @click="clearTime(); stage = 0; title = '';"
        small
        class="mr-2"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        small
        color="primary"
        @click="stage === 0 ? stage = 1 : saveTime()"
      >
        <v-icon v-if="stage == 0">mdi-arrow-right</v-icon>
        <span v-else>{{$t('save')}}</span>
      </v-btn>
    </div>
    <div style="text-align: center;" :class="$vuetify.breakpoint.mobile ? 'mt-1' : 'mt-10'">
      <v-btn
        v-if="!editing && stage == 0"
        :x-small="$vuetify.breakpoint.mobile"
        :small="!$vuetify.breakpoint.mobile"
        @click="editing = !editing"
      >
        <v-icon size="20" class="mr-2">mdi-pencil-outline</v-icon>
        {{$t('edit')}}
      </v-btn>
      <v-btn
        color="primary"
        v-if="editing && stage == 0"
        :x-small="$vuetify.breakpoint.mobile"
        :small="!$vuetify.breakpoint.mobile"
        @click="editing = !editing"
      >
        {{$t('done')}}
      </v-btn>
    </div>
    <TagAdderDialog
      :show="showTagAdder"
      @close="showTagAdder = false"
      v-model="timeTags"
    ></TagAdderDialog>
    <confirmation-dialog
      :show="deletingTimename"
      @decline="deletingTimename = null"
      @accept="deleteChosenTimename"
      btnColor="error"
      :btnText="$t('delete')"
      :text="`${$t('delete')} ${deletingTimename ? deletingTimename.name : ''}?`"
    ></confirmation-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import time from '@/utils/player/time'
import TagAdderDialog from '@/components/tag/TagAdderDialog'
import draggable from 'vuedraggable'
import ChooseTagBtn from '@/components/tag_views/ChooseTagBtn.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'

export default {
  props: ['flat'],
  created() {
    this.initTeamnames()
  },
  components: { TagAdderDialog, draggable, ChooseTagBtn, ConfirmationDialog },
  mixins: [time],
  data: () => ({
    deletingTimename: null,
    chosen: [],
    currentTimename: {},
    addingNew: false,
    title: '',
    newName: '',
    showTagAdder: false,
    timeTags: [],
    stage: 0, // 0 = timenames - 1 = title and tags
    editing: false,
    dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
    }
  }),
  methods: {
    ...mapActions('time', [
      'initTeamnames',
      'addTimename',
      'timenameClicked',
      'addTime',
      'clearTime',
      'lastTime',
      'updateTimename',
      'timenameOrder',
      'deleteTimename'
    ]),
    ...mapActions('noti', [
      'success',
      'error',
      'info'
    ]),
    ...mapMutations('videos', [
      'addTimeToVideo'
    ]),
    ...mapMutations('time', [
      'SET_TIMENAMES'
    ]),
    deleteChosenTimename() {
      this.deleteTimename(this.deletingTimename.id)
        .then(() => {
          this.info(this.$t('deleted'))
          this.deletingTimename = null
        })
        .catch(e => {
          this.error(e)
        })
    },
    hide(timename) {
      let data = {
        ...timename,
        hidden: true
      }

      this.updateTimename(data)
        .then()
        .catch(e => this.error(e))
    },
    show(timename) {
      let data = {
        ...timename,
        hidden: false
      }

      this.updateTimename(data)
        .then()
        .catch(e => this.error(e))
    },
    addNewPoint(e) {
      e.preventDefault();

      if(!this.newName) {
        this.addingNew = false
        return
      }

      this.addTimename({
        team_id: this.currentTeamId,
        name: this.newName
      })
        .then(() => {
          this.success(this.$t('analysis.point_saved') + '!')
          this.addingNew = false
          this.newName = ''
        })
        .catch(e => {
          this.error(e)
        })
    },
    saveTime() {
      this.addTime({
        title: this.title,
        video_id: this.$route.params.id,
        tag_ids: [...this.timeTags].map(t => t.id)
      })
        .then((e) => {
          this.success(this.$t('analysis.new_time_saved'))
          this.clearTime()
          this.addTimeToVideo({time: e})
          this.title = ''
          this.stage = 0
        })
        .catch(e => this.error(e))
    },
    handledName(name) {
      if(!name) return
      if(name.length < 24) return name
      return name.substring(0, 24) + '...'
    }
  },
  computed: {
    ...mapGetters('time', [
      'timenames',
      'data',
      'dataIncludesId',
      'timenameSetTime',
      'timenameOrdered',
      'firstTime'
    ]),
    ...mapGetters('user', [
      'currentTeamId'
    ]),
    ...mapGetters('player', [
      'currentTime',
      'accurateCurrentTime'
    ]),
    mappedTimenames() {
      return this.timenames?.map(t => { return {value: t.id, text: t.name} })
    }
  },
  destroyed() {
    this.clearTime()
  }
}
</script>

<style lang="scss">
// .list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

.handle {
  &:hover {
    color: black;
  }
}
</style>