<template>
  <div style="overflow-x: hidden;">
    <div class="io-timeline-cont-row-left hide-scrollbar">
      <div :style="`
        height: 90%;
        min-width: 1px;
        width: 5px;
        background-color: ${color || $vuetify.theme.currentTheme.primary};
        border-radius: 5px;
      `"
      ></div>
      <div class="ml-1 hide-scrollbar">
        {{ tag.name }}
      </div>
      <v-spacer></v-spacer>
    </div>
    <div class="io-timeline-cont-row-main hide-scrollbar">
      <div class="hide-scrollbar" :style="`width: ${whole_bar_width}px; left: -${pixels_hidden_left}px`" style="height: 28px; position: absolute; top: 0px; overflow-x: hidden;">
        <div
          v-for="clip in tag_clips(tag.id)"
          @click="seek(clip.starttime)"
          class="io-timeline-clip pl-2 hide-scrollbar"
          :style="`
            cursor: pointer;
            position: absolute;
            left: ${clip_bar_left(clip)}px;
            width: ${clip_bar_width(clip)}px;
            background-color: ${color || $vuetify.theme.currentTheme.primary};
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, .5);
          `"
          :key="clip.id"
        >
          <!-- <span class="io-timeline-clip-name">{{ clip.title }}</span> -->
          <!-- <span class="io-timeline-clip-name">{{ tag.name }}</span>
          <span class="io-timeline-clip-tags">{{ clip_name(clip, tag) }}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['tag', 'color'],
  methods: {
    ...mapActions('player', [
      'seek'
    ])
  },
  computed: {
    ...mapGetters({
      duration: 'player/duration'
    }),
    ...mapGetters('timeline', [
      'zoom',
      'left_time',
      'tag_clips',
      'main_width',
      'pixels_hidden_left',
      'whole_bar_width',
      'clip_bar_left',
      'clip_bar_width',
      'clip_name'
    ])
  }
}
</script>
<style lang="scss" scoped>
.io-timeline-cont-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: hidden;

  &-left {
    width: 100px;
    padding-left: 8px;
    padding-top: 2px;
    display: flex;
    text-wrap: nowrap;
    overflow-x: scroll;
    z-index: 1;
    border-right: 2px solid grey;

    &:hover {
      overflow-x: visible;
      cursor: default;
      border-right: 0px solid grey;
    }
  }

  &-main {
    overflow: hidden;
    align-self: auto;
    position: relative;
    flex-grow: 1;
  }
}

.io-timeline-clip {
  display: flex;
  flex-wrap: none;
  border-radius: 1px;
  height: 100%;
  color: white;
  overflow-y: hidden;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgb(132, 132, 132);

  &:hover {
    z-index: 1;
    transition-duration: 0.2s;
    padding-left: 100px;
    overflow: visible;
  }

  &-name {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  &-tags {
    display: none;
    white-space: nowrap;
    z-index: 1;
  }
}

.io-timeline-clip:hover .io-timeline-clip-tags {
  display: block;
  z-index: 2;
  overflow-x: visible;
}

.io-timeline-clip:hover .io-timeline-clip-name {
  width: 0;
}
</style>