<template>
  <div class="pa-3 pb-6" style="display: flex; flex-direction: column; justify-content: space-between; height: calc(100% - 80px);">
    <div style="text-align: center;" v-if="uiState == 1 && clipJustCreated">
      <div class="text-h5">
        <v-icon>mdi-format-horizontal-align-center</v-icon>
        {{ clipJustCreated.title }}
        <!-- {{ $t('clips.clip_ready') }} -->
      </div>
      <v-btn class="mt-4" block small @click="$router.push(`/editor?clip_id=${clipJustCreated.id}`)">
        <v-icon class="mr-2">mdi-draw</v-icon>
        {{ $t('draw_on_clip') }}
      </v-btn>
      <v-btn class="mt-4" block small @click="$emit('openClips'); openClip(clipJustCreated)">
        <v-icon class="mr-2">mdi-eye-outline</v-icon>
        {{ $t('show_clip') }}
      </v-btn>
      <share-btn
        class="mt-4"
        :resource_id="clipJustCreated.id"
        resource_type="clip"
        size="small"
        :block="true"
      />
      <v-btn class="mt-4" block small color="primary" @click="uiState = 0; clipJustCreated = null">
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t('create_more_clips') }} (Enter)
      </v-btn>
      <!-- {{ clipJustCreated }} -->
    </div>
    <div v-else>
      <div style="display: flex; flex-direction: row; justify-content: space-around;">
        <div>
          <v-tooltip right>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                ref="record"
                style="height: 50px; border-radius: 50%;"
                @click="handleRecordClick(); $event.target.blur();"
                class="mt-2"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-expand-x-transition>
                  <v-icon v-if="starttime && !endtime">mdi-pause</v-icon>
                </v-expand-x-transition>
                <v-expand-x-transition>
                  <v-icon v-if="!starttime && !endtime" color="red">mdi-circle</v-icon>
                </v-expand-x-transition>
                <v-expand-x-transition>
                  <v-icon v-if="starttime && endtime" color="red">mdi-close</v-icon>
                </v-expand-x-transition>
              </v-btn>
            </template>
            <span v-if="starttime && !endtime">
              {{  $t('end_clip') }} (R)
            </span>
            <span v-else-if="starttime && endtime">
              {{ $t('discard_clip') }} (esc)
            </span>
            <span v-else>
              {{ $t('record_clip') }} (R)
            </span>
          </v-tooltip>
        </div>
        <!-- <div class="io-labeled-btn">
          <div class="io-labeled-btn-text">
            {{ $t('analysis.endtime')}} (d)
          </div>
          <v-btn
            :x-small="isAnalysis"
            :disabled="!starttime"
            @click="end()"
            :color="endtime ? 'primary' : ''"
          >
            <v-expand-x-transition>
              <v-icon
                size="16"
                v-if="endtime"
                transition="scroll-y-transition"
              >
                mdi-pin
              </v-icon> cursor: pointer;
            </v-expand-x-transition>
            {{ endBtnText }}
          </v-btn>
        </div> -->
      </div>
      <v-expand-x-transition>
        <div style="height: 20px;" class="d-flex justify-center mt-5 mb-2">
          <v-expand-x-transition v-if="starttime">
            <v-icon v-if="!endtime" color="red" class="blink mr-2">mdi-circle</v-icon>
            <v-icon v-else class="mr-2">mdi-format-horizontal-align-center</v-icon>
          </v-expand-x-transition>
          <div v-if="starttime">
            {{ secondsToPretty((endtime || currentTime) - starttime) }}
          </div>

        </div>
      </v-expand-x-transition>

      <!-- <div
        transition="slide-x-transition"
        v-if="false"
        class="mt-3"
        style="display: flex; justify-content: space-around;"
      >
        <v-card :height="canvasHeight">
          <canvas
            @click="start()"
            id="canvas"
            :width="canvasHeight * 16 / 9"
            :style="`border-radius: 5px; cursor: pointer;`"
            :height="canvasHeight"
          ></canvas>
        </v-card>
        <v-card :height="canvasHeight">
          <canvas
            @click="end()"
            id="canvas1"
            :width="canvasHeight * 16 / 9"
            :style="`border-radius: 5px; cursor: pointer;`"
            :height="canvasHeight"
          >
          </canvas>
          <v-overlay
            v-if="!starttime"
            :absolute="true"
          ></v-overlay>
        </v-card>
      </div> -->
      <v-text-field
        class="mt-4"
        elevation="10"
        autocomplete="false"
        :placeholder="$t('analysis.clip_title')"
        :label="$t('analysis.clip_title')"
        v-prevent-keys
        solo
        dense
        v-model="clipTitle"
        counter="50"
        ref="clipTitle"
      >
      </v-text-field>
      <v-textarea @focus="descriptionFocused = true" @blur="descriptionFocused = false" v-prevent-keys solo v-model="clipDescription"  :placeholder="$t('analysis.clip_comments')">

      </v-textarea>
      <div class="mb-5">
        <div class="d-flex align-center justify-space-around">
          <choose-folder-btn
            :xsmall="true"
            v-model="chosenFolders"
            @cancel="chosenFolders = []"
          ></choose-folder-btn>
          <choose-tag-btn
            v-model="clipTags"
          ></choose-tag-btn>
          <v-btn text x-small @click="choosingMapBase = true">
            <v-icon class="mr-2" :size="20">mdi-plus</v-icon>
            {{$t('analysis.add_map_point')}}
          </v-btn>
        </div>
      </div>
      <div class="mt-0" style="display: flex; justify-content: center;">
        <!-- <v-btn small @click="clear()">clear</v-btn> -->
        <v-btn
          @click="save()"
          :loading="saving"
          small
          color="primary"
          :disabled="!starttime || !endtime"
        >{{$t('save')}} (Enter)</v-btn>
      </div>
      <div class="mt-4" style="display: flex; justify-content: center;">
        <v-btn
          @click="openInEditor()"
          text
          x-small
          :disabled="!starttime || !endtime || (!clipTitle && !isAnalysis)"
        >
          <v-icon>mdi-arrow-top-right</v-icon>
          {{$t('clip.open_id_editor')}}
        </v-btn>
      </div>
      <div>
        <div
          v-for="base_id in currentClipUniqueBases"
          :key="base_id + 'clipmap'"
          style="display: flex; flex-direction: row;"
        >
          <Map
            style="align-self: center; flex-grow: 1;"
            :base="baseById(base_id)"
            :value="pointsByBaseId(base_id)"
            :maxHeight="100"
            :maxWidth="100"
            :disableClicks="true"
            :hideButtons="true"
          ></Map>
          <div
            @click="removePointByBaseId(base_id)"
            style="align-self: center;"
          >
            <v-btn class="px-0" x-small text>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <v-overlay
        :value="saved"
        :absolute="true"
      >
        <v-icon
          size="30"
          color="green"
        >mdi-check</v-icon>
      </v-overlay>
    </div>
    <v-spacer></v-spacer>
    <div style="text-align: center;" class="mt-10">
      <v-btn
        text
        class="mx-auto"
        small
        @click="showAdvancedOptions = true"
      >
        <v-icon class="mr-3">mdi-cog-outline</v-icon>
        {{$t('clips.advanced_options')}}
      </v-btn>
      <!-- <v-btn
        text
        class="mx-auto mt-3"
        small
        @click="showRulesDialog = true"
      >
        <v-icon class="mr-3">mdi-arrow-u-right-top</v-icon>
        {{$t('clips.rules')}}
      </v-btn> -->
    </div>
    <!-- < NOT VISIBLE AT FIRST > -->

    <!-- MAP -->
    <v-dialog v-model="choosingMapBase" max-width="700">
      <add-map
        @close="choosingMapBase = false"
        @startDrawing="drawMapKey++; drawing_base = $event; drawingMap = true; choosingMapBase = false"
      ></add-map>
    </v-dialog>
    <v-dialog
      max-width="700"
      v-model="drawingMap"
      permanent
    >
      <draw-map
        @close="drawing_base = null; modify_map = null; drawingMap = false;"
        @saved="startAddPointsToClip($event); drawingMap = false;"
        :base="drawing_base"
        :modify="modify_map"
        :dense="true"
        :point_only="true"
        :key="drawMapKey"
      ></draw-map>
    </v-dialog>

    <!-- ADVANCED OPTIONS -->
    <v-dialog
      @click:outside="showAdvancedOptions = false"
      v-model="showAdvancedOptions"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          {{$t('clips.advanced_options')}}
        </v-card-title>
        <v-card-text>
          <v-switch
            v-model="settings.autoOpenTags"
            @change="saveSetting('autoOpenTags', $event)"
            :label="$t('clips.open_after_ending') + (settings.autoOpenTags ? $t('yes') : $t('no'))"
            color="primary"
            hide-details
          ></v-switch>
          <v-switch
            v-model="settings.pauseAfterEndingClip"
            @change="saveSetting('pauseAfterEndingClip', $event)"
            :label="$t('clips.pause_after_ending') + (settings.pauseAfterEndingClip ? $t('yes') : $t('no'))"
            color="primary"
            hide-details
          ></v-switch>
          <v-switch
            v-model="settings.startAfterSavingClip"
            @change="saveSetting('startAfterSavingClip', $event)"
            :label="$t('clips.start_after_saving') + (settings.startAfterSavingClip ? $t('yes') : $t('no'))"
            color="primary"
            hide-details
          ></v-switch>
          <v-switch
            v-model="settings.focusTitleAfterEnding"
            @change="saveSetting('focusTitleAfterEnding', $event)"
            :label="$t('clips.focus_after_end') + ' - ' + (settings.focusTitleAfterEnding ? $t('yes') : $t('no'))"
            color="primary"
            hide-details
          ></v-switch>
        </v-card-text>
        <v-card-title>
          {{$t('clips.form_clip_title')}}
        </v-card-title>
        <v-card-text>
          {{$t('clips.form_long')}}
          <div class="mt-3">
            <v-chip
              v-for="group in tag_groups"
              :key="'automaticchip' + group.id"
              @click="groupClicked(group)"
              :color="groupChosen(group) ? 'primary' : ''"
            >
              {{group.group_name}} {{groupChosen(group) ? groupIndex(group) + 1 : ''}}
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="showAdvancedOptions = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TagViewsDialog
      mode="choose"
      :show="showTagAdder"
      v-if="showTagAdder"
      v-model="clipTags"
      @close="showTagAdder = false"
      @set="showTagAdder = false"
    ></TagViewsDialog>
    <!-- <TagAdderDialog
      v-if="!isAnalysis"
      :show="showTagAdder"
      :isAnalysis="isAnalysis"
      :tagging="true"
      @close="showTagAdder = false"
      @set="tagAdderClosed()"
      @tag_clicked="handleTagClick($event)"
      v-model="clipTags"
    ></TagAdderDialog> -->
    <!-- <rules-dialog
      @close="showRulesDialog = false"
      :show="showRulesDialog"
    ></rules-dialog> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import time from '@/utils/player/time'
// import TagAdderDialog from '@/components/tag/TagAdderDialog'
// import TagAdder from '@/components/tag/TagAdder'
// import RulesDialog from './RulesDialog.vue'
import { handleChosenTagsCurrentClip, handleChosenTagsNextClip, handleChosenTagsHotkey } from '@/utils/clipper/handleRules.js'
import AddMap from '../../maps/AddMap.vue'
import DrawMap from '../../maps/DrawMap.vue'
import Map from '../../maps/Map.vue'
import ChooseFolderBtn from '../../folder/ChooseFolderBtn.vue'
import ShareBtn from '@/components/share/ShareBtn.vue'
import ChooseTagBtn from '@/components/tag_views/ChooseTagBtn.vue'

export default {
  props: ['tags'],
  mixins: [ time ],
  components: {
    // TagAdder,
    // TagAdderDialog,
    AddMap,
    DrawMap,
    Map,
    ChooseFolderBtn,
    ShareBtn,
    ChooseTagBtn
  },
  shortcuts: {
    enter: function() {
      if(this.uiState == 0) this.save()
      else if(this.uiState == 1) {
        this.uiState = 0
        this.clipJustCreated = null
      }
    },
    keydown: function (e) {
      if(this.keybinds) {
        this.clipTags = handleChosenTagsHotkey(this.clipTags, this.rules, this.tag_groups, undefined, e.key)

        if(e.code == 'KeyR' && !this.endtime) this.handleRecordClick()
      }
      if(e.code == 'Escape') {
        this.discardClip()
      }
      return this.keybinds
    },
    w: function() {
      this.start()
    },
    d: function() {
      this.end()
    }
  },
  created() {
      // const canvas = document.getElementById('canvas');
      // const ctx     = canvas.getContext('2d');
      // const canvas1 = document.getElementById('canvas1');
      // const ctx1    = canvas1.getContext('2d');
      // ctx.scale(1/this.ctxScale, 1/this.ctxScale)
      // ctx1.scale(1/this.ctxScale, 1/this.ctxScale)

      // this.startDrawing()
    if(!this.tag_groups?.length) {
      this.initTags(this.currentTeamId)
        .then()
        .catch(e => this.error(e))
    }

    // if(!this.rules) {
    //   this.initRules()
    // }

    let sett = window.localStorage.getItem('tiimi-settings')
    if(sett) {
      try {
        this.settings = JSON.parse(sett)
      } catch {
        ''
      }
    }
  },
  data: () => ({
    canvasHeight: 50,
    ctxScale: 14.5,
    starttime: null,
    endtime: null,
    interval: null,
    uiState: 0,
    clipTitle: '',
    clipDescription: '',
    saving: false,
    saved: false,
    showTagAdder: false,
    clipTags: [],
    showAdvancedOptions: false,
    descriptionFocused: false,

    clipPoints: [],

    choosingMapBase: false,
    drawing_base: false,
    drawingMap: false,
    modify_map: null,
    drawMapKey: 0,

    settings: {
      autoOpenTags: false,
      pauseAfterEndingClip: false,
      focusTitleAfterEnding: false,
      startAfterSavingClip: false,
      createTitleAfterEndingClip: false,
      chosenGroups: []
    },
    showRulesDialog: false,
    chosenFolders: [],
    clipJustCreated: null
  }),
  computed: {
    ...mapGetters('player', [
      'currentTime'
    ]),
    ...mapGetters([
      'keybinds'
    ]),
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    ...mapGetters('rule', [
      'rules'
    ]),
    ...mapGetters('user', [
      'currentTeamId'
    ]),
    currentClipUniqueBases() {
      let bases = []

      this.clipPoints.forEach(p => {
        if(!bases.includes(p.map_base.id)) {
          bases.push(p.map_base.id)
        }
      })

      return bases
    },
    moddedClipTags() {
      if(!this.clipTags) return []
      if(!this.clipTags.length) return []

      return this.clipTags.map(tag => tag.id)
    },
    startBtnText() {
      return this.starttime
        ? this.secondsToPretty(this.starttime)
        : this.secondsToPretty(this.currentTime)
    },
    endBtnText() {
      return this.endtime
        ? this.secondsToPretty(this.endtime)
        : this.secondsToPretty(this.currentTime)
    },
    isAnalysis() {
      return this.$route.path.includes('analysis')
    }
  },
  methods: {
    ...mapActions('clip', [
      'addClip'
    ]),
    ...mapActions('folder', [
      'addFolder'
    ]),
    ...mapActions('player', [
      'pause',
      'play',
      'openClip',
      'seek'
    ]),
    ...mapActions('noti', [
      'success'
    ]),
    ...mapActions('rule', [
      'initRules'
    ]),
    ...mapActions('tag', [
      'initTags'
    ]),
    ...mapActions('map', [
      'addPointToClip'
    ]),
    copyItem() {

    },
    pasteItem() {

    },
    discardClip() {
      this.starttime = null
      this.endtime = null
      this.clipTitle = ''
      this.$refs.clipTitle.blur()
    },
    handleRecordClick() {
      if(this.starttime && this.endtime) {
        this.discardClip()
      } else if(this.starttime && !this.endtime) this.end()
      else {
        this.start()
        this.play()
      }
    },
    handleTagClick(e) {
      if(this.isAnalysis) this.setTitle()

      this.clipTags = handleChosenTagsCurrentClip(this.clipTags, this.rules, this.tag_groups, e)
    },
    baseById(id) {
      let point = this.clipPoints.find(p => p.map_base.id == id)
      return point?.map_base
    },
    pointsByBaseId(id) {
      return this.clipPoints.filter(p => p.map_base.id == id)
    },
    groupClicked(group) {
      if(this.groupChosen(group)) this.settings.chosenGroups = this.settings.chosenGroups.filter(g => g != group.id)
      else this.settings.chosenGroups = this.settings.chosenGroups.concat(group.id)

      this.$nextTick(() => {
        window.localStorage.setItem('tiimi-settings', JSON.stringify(this.settings))
      })
    },
    saveSetting(name, event) {
      this.settings[name] = event
      this.$nextTick(() => {
        window.localStorage.setItem('tiimi-settings', JSON.stringify(this.settings))
      })
    },
    groupChosen(group) {
      return this.settings.chosenGroups.includes(group.id)
    },
    groupIndex(group) {
      return this.settings.chosenGroups.findIndex(g => g == group.id)
    },
    start() {
      if(this.starttime && this.endtime) {
        this.endtime = null
      } else if(this.starttime && !this.endtime) {
        this.starttime = null
        this.endtime = null
        return
      }

      this.starttime = this.currentTime
      console.log(this.currentTime)
    },
    drawStart() {
      let canvas = document.getElementById('canvas');
      let ctx    = canvas.getContext('2d');
      let video  = document.querySelector('#io-player video');
      if(!canvas || !ctx || !video) return
      ctx.drawImage(video, 0, 0);
    },
    drawEnd() {
      let canvas1 = document.getElementById('canvas1');
      let ctx    = canvas1.getContext('2d');
      let video  = document.querySelector('#io-player video');
      if(!canvas1 || !ctx || !video) return
      ctx.drawImage(video, 0, 0);
    },
    end() {
      if(!this.starttime) return
      if(this.endtime) {
        this.endtime = null
        return
      }

      // this.drawEnd()

      if(this.settings.pauseAfterEndingClip) this.pause()

      if(this.settings.autoOpenTags) {
        this.showTagAdder = true
      } else {
        setTimeout(() => {
          this.$refs.clipTitle.focus()
        }, 50)
      }

      this.endtime = this.currentTime
      if(this.isAnalysis) this.setTitle()
    },
    tagAdderClosed() {
      if(!this.starttime || !this.endtime) return
      if(this.settings.chosenGroups.length) {
        this.setTitle()
      } else {
        this.$refs.clipTitle.focus()
      }
    },
    setTitle() {
      let title = ''
      this.settings.chosenGroups.forEach((group, i) => {
        title += this.clipTags.filter(tag => tag.group_id == group)[0]?.tag_name || ''
        if(i < this.settings.chosenGroups.length - 1) title += ' '
      })

      this.clipTitle = title.trim() || 'No title'
    },
    clearCanvases() {
      let canvas = document.getElementById('canvas')
      let ctx    = canvas.getContext('2d')

      let canvas1 = document.getElementById('canvas')
      let ctx1   = canvas1.getContext('2d')

      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx1.fillRect(0, 0, canvas1.width, canvas1.height);
    },
    clear() {
      this.starttime = null
      this.endtime = null
      this.clipPoints = []

      // this.clearCanvases()
    },
    draw() {
      if(!this.starttime) this.drawStart()
      if(!this.endtime) this.drawEnd()
    },
    startDrawing() {
      this.interval = setInterval(() => {
        this.draw()
      }, 50)
    },
    save(openInEditor) {
      if(!this.starttime || !this.endtime) return
      this.saving = true
      if(this.settings.startAfterSavingClip) this.play()

      const isAnalysis = this.$route.path.includes('analysis')
      const isGame = this.$route.path.includes('games') || isAnalysis

      this.addClip({
        leaguewide: isAnalysis,
        title: this.clipTitle  || 'New clip',
        description: this.clipDescription,
        starttime: this.starttime,
        endtime: this.endtime || this.currentTime,
        video_id: isGame ? undefined : this.$route.params.id,
        game_id: !isGame ? undefined : this.$route.params.id,
        tags: this.moddedClipTags,
        points: this.clipPoints
      })
      .then((e) => {
        // this.saved = true
        if(this.isAnalysis) this.seek(this.endtime)
        this.clipTags = handleChosenTagsNextClip(this.clipTags, this.rules, this.tag_groups)
        this.clear()
        // this.$refs.tagAdder.clear()
        // this.clipTags = []
        // setTimeout(() => {
        //   this.clipTitle = ''
        //   this.uiState = 0
        //   this.saved = false
        // }, 1000)
        this.success(this.$t('analysis.clip_created') + '!')
        this.clipTitle = ''

        if(this.chosenFolders && this.chosenFolders.length) {
          this.addCreatedClipToFolders(e.data)
        }

        this.track('Clip created', {
          ...e.data
        })

        this.clipJustCreated = e.data
        this.uiState = 1

        if(openInEditor) {
          this.$router.push(`/editor?clip_id=${e.data.id}`)
        }
      })
      .catch(e => {
        console.log(e)
        alert(e)
      })
      .finally(() => {
        this.saving = false
      })
    },
    openInEditor() {
      this.save(true)
    },
    startAddPointsToClip(points) {

      points = points.map(p => {
        return {
          ...p, map_base: this.drawing_base
        }
      })

      this.clipPoints = this.clipPoints.concat(points)
    },
    removePointByBaseId(id) {
      this.clipPoints = this.clipPoints.filter(p => p.map_base.id != id)
    },
    addCreatedClipToFolders(e) {
      const folderPromises = this.chosenFolders.map(f => {
        return this.addFolder({
          parent: f.id,
          name: e.title,
          type: 'clip',
          clip_id: e.id
        })
      })

      Promise.all(folderPromises)
        .then()
        .catch(e => {
          this.error(e)
        })
    }
  },
  watch: {
    currentTime: {
      handler(newVal) {
        if(this.isAnalysis && this.starttime && this.endtime && newVal > this.endtime) {
          this.seek(this.endtime - 5)
        }
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
  .io-labeled-btn {
    display: flex;
    flex-direction: column;
    text-align: center;

    &-text {
      color: lightgrey;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  .blink {
    animation: blink-animation 1.5s steps(30, start) infinite;
    -webkit-animation: blink-animation 1.5s steps(30, start) infinite;
  }
  @keyframes blink-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes blink-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>