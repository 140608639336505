export default {
  methods: {
    adjustHexColor(hex, percent) {
      // Remove the hash at the start if it's there
      hex = hex.replace(/^#/, '');

      // Parse the r, g, b values
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      // Adjust color, increase values to lighten, decrease to darken
      r = Math.max(0, Math.min(255, Math.floor(r * (1 + percent / 100))));
      g = Math.max(0, Math.min(255, Math.floor(g * (1 + percent / 100))));
      b = Math.max(0, Math.min(255, Math.floor(b * (1 + percent / 100))));

      // Convert back to hex
      const adjustedColor = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

      return adjustedColor;
    }
  }
}
